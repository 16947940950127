import { Component, AfterViewInit, OnInit } from '@angular/core';
import { EntriesService } from '../services/entries.service';
import { PersonsService } from '../services/persons.service';
import { errorNotification, successNotification } from '../services/notifications.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { CardService } from '../services/card.service';
import { Firestore, WriteBatch, writeBatch } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { EmailsService } from './../services/emails.service';
import { firstValueFrom } from 'rxjs';
import { environment } from './../../environments/environment';
import { Club } from '../enums/clubs';
declare var $: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements AfterViewInit, OnInit {
  public drafts: any[];
  public paying: any[];
  public showInfo: any;
  public actions: any;
  public addNewCard: boolean;
  public cardForm: FormGroup;
  public cartTotals: any;
  public email: string;
  public showID: string;
  public club_id: string;
  private showName: string;
  private uid: string;
  public stripeCustomer: StripeCustomer;
  public metadata: any;
  public description: string;
  public defaultCardHolder: string;

  constructor(
    private _entriesService: EntriesService,
    private _personsService: PersonsService,
    private _authService: AuthService,
    private _cardService: CardService,
    private _router: Router,
    private _firestore: Firestore,
    private _emailsService: EmailsService
  ) {
    this.cartTotals = {
      total: 0,
      entries: 0
    }
    this.showInfo = {};
    this.drafts = [];
    this.paying = [];
    this.actions = {
      canGoToPayment: false,
      cardFormIsOK: false,
      StripeFormVisible: false,
      moveObject: true
    }
    this.addNewCard = false;
    this.cardForm = new FormGroup({
      cardNumber: new FormControl('', [Validators.required, Validators.pattern(/^\d{4} \d{4} \d{4} \d{3,4}$/)]),//Este campo es obligatorio y debe tener 15 o 16 digitos
      expirationDate: new FormControl('', [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/\d{2}$/)]),
      cvv: new FormControl('', [Validators.required, Validators.pattern(/^\d{3,4}$/)]),
      cardHolderName: new FormControl('', [Validators.required]),
    });
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.showID = sessionStorage.getItem('showID')||'';
    this.showName = sessionStorage.getItem('showname')||'';
    this.club_id = sessionStorage.getItem('club_id')||'';
    this.uid = localStorage.getItem('user_document_id')||(sessionStorage.getItem('user_document_id')||'');
    this.stripeCustomer = { id: '', name: '' };
    this.metadata = null;
    this.description = '';
    this.defaultCardHolder = '';
  }

  async ngOnInit() {
    const user = await this._authService.getLoggedUser();
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    if(!this.uid){
      this._personsService.getDocumentByEmail(this.email).then((doc: any) => {
        this.uid = doc.uid;
        localStorage.setItem('user_document_id', this.uid);
        console.log('User document id: ', this.uid);
      });
    }

    this.getStripeCustomer();
  }

  ngAfterViewInit(): void {
    this.getConcurso();
  }

  public async getConcurso() {
    const response: any = await this._entriesService.getEntryInfo(this.showID);
    if(response.error) {
      errorNotification('Error', response.message);
      return;
    }
    console.log('getConcurso', response);
    // Set the showInfo properties with the response data
    this.showInfo.entryAmount = response.entryAmount;
    this.showInfo.entriesDue = response.entriesDue;
    this.showInfo.cardPayment = response.cardPayment;
    this.showInfo.checkPayment = response.checkPayment;
    this.showInfo.square_config = response.square_config || null;
    this.getDrafts();

    return response;
  }

  public async getDrafts() {
    const response = await this._personsService.getDocumentByEmail(this.email);

    const responseDrafts = await this._entriesService.getEntries(response.uid, this.showID, 'draft');
    if (responseDrafts.error) {
      errorNotification('Error', `Error getting drafts. ${responseDrafts.message}`);
      return;
    }
    this.drafts = responseDrafts;
  }

  async getStripeCustomer() {
    let consultar = false;
    //1. Getting Payment Info...
    // Check if the user has a stripe customer id
    let { customer, reason } = await this._cardService.getStripeCustomer(this.uid, sessionStorage.getItem('club_id')||'').then(customer => ({ customer, reason: null })).catch(reason => ({ reason, customer: null }));
    //Validar si el cliente existe, tiene customer_id y el id no contiene el prefijo 'cus_' (No es un id de stripe valido)
    if(customer && (customer.customer_id||'') && !(`${customer.customer_id||''}`.includes('cus_'))){
      await this._cardService.removeStripeCustomerId(this.uid, sessionStorage.getItem('club_id')||'');
      reason = {
        error: {
          code: 'invalid_customer_id',
          message: `Invalid Stripe Customer ID: ${customer.customer_id||''}`
        }
      };
      consultar = true;
      this.sendErrorEmail(reason.error.code, reason.error.message, this.email);
      console.log(reason);
      //return errorNotification('Error', `Invalid Stripe Customer ID: ${customer.customer_id||''}`);
    } else if(customer && !(customer.customer_id||'')){//Si existe el objeto cliente pero no tiene customer_id
      await this._cardService.removeStripeCustomerId(this.uid, sessionStorage.getItem('club_id')||'');
      reason = {
        error: {
          code: `not_found`,
          message: `Empty Stripe Customer ID, user: ${this.uid} and show_id ${this.showID} ${customer.customer_id||''}`
        }
      };
      consultar = true;
      this.sendErrorEmail(reason.error.code, reason.error.message, this.email);
      //return errorNotification('Error', `Empty Stripe Customer ID, user: ${this.uid} and show_id ${showID} ${customer.customer_id||''}`);
    }

    if(consultar){
      //const customers = await this._cardService.searchStripeCustomers([this.email], +(this.showID), !environment.production);
      //customer get the first customer if exists from array customers
      const [firstCustomer] = await this._cardService.searchStripeCustomers([this.email], +(this.showID), !environment.production);
      if(firstCustomer){
        reason = null;
        customer = { customer_id: firstCustomer.id, name: firstCustomer.name||'' };
      }
    }

    //Validar si existe un error
    if(reason && reason.error && ['not_found', 'invalid_customer_id'].includes(reason.error.code)){
      // If the user doesn't have a stripe customer id, create one
      //2. Creating Customer...
      const { customer, reason } = await this._cardService.createStripeCustomer(this.uid, this.showID, sessionStorage.getItem('club_id')||'', this.email).then((r: any) => r).catch(reason => ({ reason }));
      if(reason) return errorNotification('Error', reason.error.message||(reason.error||reason));
      this._emailsService.sendEmail('User Created Stripe nvm ' + this.email + ' with ID: ' + customer.id, `User: ${this.email}  Selected Show: ${this.showName} with ID: ${this.showID} and Club ID: ${sessionStorage.getItem('club_id')||''}`);
      //3. Payment Info OK, setting up info...
      this.stripeCustomer.id = customer.id;
      this.stripeCustomer.name = customer.name||'';
    } else if(reason){
      this.sendErrorEmail('4E. Error getting Stripe Customer ID', 'unknown error', this.email);
      return errorNotification('Error', reason.error.message||(reason.error||reason));
    } else{
      //4. Payment Info OK, setting up info...
      this.stripeCustomer.id = customer.customer_id;
      this.stripeCustomer.name = customer.name||'';
    }
  }

  public async deleteDraft(uid: string) {
    const { reason } = await this._entriesService.deleteDocument(this.uid, uid).then(() => ({ reason: null })).catch(reason => ({ reason }));
    if (reason) {
      errorNotification(reason.error.code, `Error deleting draft. ${reason.error.message}`);
      return;
    }
    this.getDrafts();
  }

  public moveObject(object: any, sourceArray: any[], destinationArray: any[]): void {
    const index = sourceArray.indexOf(object);
    if (index !== -1) {
      sourceArray.splice(index, 1); // Remove the object from the source array
      destinationArray.push(object); // Add the object to the destination array
    }
    this.cartTotals.total = this.paying.reduce((acc, e) => acc + parseFloat(e.balance||(this.showInfo.entryAmount||'0')), 0);
    this.actions.canGoToPayment = (this.paying.length > 0);
    this.setMetadata();
  }

  public selectPaymentMethod() {
    this.actions.StripeFormVisible = true;
    this.actions.moveObject = false;
  }

  public returnToSelectEntries() {
    this.actions.StripeFormVisible = false;
    this.actions.moveObject = true;
  }

  public async handleSubmit(paymentIntent: any){
    const batch: WriteBatch = writeBatch(this._firestore);
    const entries: Solicitud[] = this.paying.map<Solicitud>(e => {
      batch.set(e.ref, { status: 'Pending Show Approval' }, { merge: true });
      const additionalRiders = e.riders.map((r: any) => ({
        riderUsef: r.USEF || (r.riderUSEF || ''),
        riderName: r.firstname || (r.riderFirstname || ''),
        riderMiddleName: '',
        riderLastName: r.lastname || (r.riderLastname || ''),
        riderFei: r.FEI || (r.riderFEI || ''),
        //Address
        riderAddress: r.riderAddress || '',
        riderAddress2: r.riderAddress2 || '',
        riderCountry: r.riderCountry || '',
        riderCity: r.riderCity || '',
        riderState: r.riderState || '',
        riderZip: r.riderZip || '',
        //End Address
        riderEmail: '',
        riderPhone: r.riderPhonenumber || '',
        riderSSN: '',
        riderDOB: '',
        riderNationality: '',
        suspensionStatus: '',
        signatureRider: '',
      }));
      const [jinete] = additionalRiders;
      const entry_amount = parseFloat(e.balance||(this.showInfo.entryAmount||'0'));
      const entry_charge = entry_amount * (1+paymentIntent.cardFee);

      return {
        idConcurso: this.showID,
        nombreConcurso: this.showName,
        email: this.email,
        caballo: {
          horseUsef: e.horseUSEF || '',
          horseName: e.horseName || '',
          horseFei: e.horseFEI || '',
          horseBreed: '',
          horseSex: '',
          horseHgt: '',
          horseColor: '',
          horseYrFoaled: '',
          horseSire: '',
          horseDam: '',
          horseBreeder: '',
          horseOwner: ''
        },
        jinete,
        additionalRiders,
        idEntrenador: null,
        entrenador: {
          trainerName: e.trainername || '',
          trainerUsef: e.trainerUSEF || '',
          trainerLastname: e.trainerLastname || '',
          trainerEmail: '',
          trainerPhone: e.trainerPhonenumber || '',
          //Address
          trainerAddress: e.trainerAddress || '',
          trainerAddress2: e.trainerAddress2 || '',
          trainerCountry: e.trainerCountry || '',
          trainerCity: e.trainerCity || '',
          trainerState: e.trainerState || '',
          trainerZip: e.trainerZip || '',
          signatureTrainer: ''
          //End Address
        },
        responsable: {
          payeeUsef: e.payeeUSEF || '',
          payeeName: e.payeename || '',
          payeeMiddleName: '',
          payeeLastName: e.payeeLastname || '',
          payeeSSN: '',
          payeePhone: e.payeePhonenumber || '',
          //Address
          payeeAddress: e.payeeAddress || '',
          payeeAddress2: e.payeeAddress2 || '',
          payeeCountry: e.payeeCountry || '',
          payeeCity: e.payeeCity || '',
          payeeState: e.payeeState || '',
          payeeZip: e.payeeZip || '',
          //End Address
          payeeIncorporated: ''
        },
        propietario: {
          ownerFEI: e.ownerFEI,
          ownerName: e.ownername,
          ownerLastName: e.ownerLastname,
          ownerUsef: e.ownerUSEF,
          ownerPhone: e.ownerPhonenumber,
          ownerMiddleName: '',
          //Address
          ownerAddress: e.ownerAddress || '',
          ownerAddress2: e.ownerAddress2 || '',
          ownerCountry: e.ownerCountry || '',
          ownerCity: e.ownerCity || '',
          ownerState: e.ownerState || '',
          ownerZip: e.ownerZip || '',
          signatureOwner: ''
          //End Address
        },
        pruebas: e.pruebas || [],
        cargos: e.conceptos||[],
        pago: {
          customer: paymentIntent.customer||'',
          metodo: paymentIntent.method||'tarjeta',
          pagado: paymentIntent.status == 'succeeded',
          notas: paymentIntent.id,
          transactionId: null,
          entry_amount,
          //Agregar comision
          entry_charge,
        },
        estatus: paymentIntent.status == 'succeeded' ? '1' : '0',
        otros: {
          stableWith: e.stablingWith || '',
          arrivalDate: ''
        },
        taxId: e.taxID || '',
        fingerprint: {},
        retryTrace: paymentIntent.id,
        emergencyContact: {
          name: e.emergencyContact || '',
          phone: e.emergencyPhone || ''
        }
      };
    });
    this.actions.StripeFormVisible = false;
    const { error } = await firstValueFrom(this._entriesService.crearSolicitudes(entries)).catch(reason => {
      const message = (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.'))));
      this.sendErrorEmail('Error creating online entry', `Email: ${this.email}, ${Object.entries<any>(this.metadata).map(([key, value]) => `${key}: ${value}`).join(', ')}, Error: ${message}`, this.email, true);
      errorNotification('Error', `Error generating online entry. Please try again later. ${message}`);
      return { error: message };
    });
    //Commit the batch and redirect to the home page
    await batch.commit();
    //Despues de actualizar en firebase cortar ejecucion si hay error
    if(error) return;
    this.setMailchimpMember();
    this.getDrafts();
    this._router.navigate(['/']);
    successNotification('Successful payment', 'The payment has been made successfully.');
    return;
  }

  public async handleError(error: string){
    if(error.includes("No such customer: 'cus_")){
      this.actions.StripeFormVisible = false;
      //Remove the customer id from the user document
      const response = await this._cardService.removeStripeCustomerId(this.uid, this.club_id).catch((error: any) => ({ error }));
      if(response.error) return errorNotification('Error', `${response.error.message}`)

      //Create a new customer
      const { customer, reason } = await this._cardService.createStripeCustomer(this.uid, this.showID, this.club_id, this.email).then((r: any) => r).catch((reason: any) => ({ reason }));
      if(reason) return errorNotification('Error', `${reason.error.message}`);
      //Set the stripe customer id in the session storage
      this.stripeCustomer.id = customer.id||'';
      this.actions.StripeFormVisible = true;
      return;
    }
    return errorNotification('Error', `${error}`);
  }

  private setMetadata(){
    let metadata: any = {
      systemVersion: environment.version
    };
    if(this.paying.length == 0){
      this.metadata = metadata;
      return;
    };
    metadata[`user`] = this.uid;
    this.paying.forEach((entry: any, i: number) => {
      metadata[`entry${i+1}`] = entry.uid||'';
    });
    this.metadata = metadata;
    const [firstEntry] = this.paying;
    if(firstEntry) this.defaultCardHolder = `${firstEntry.trainername||''} ${firstEntry.trainerLastname||''}`;
    this.description = `Show: ${this.showName}, Total online entries: ${this.paying.length}, horses: ${this.paying.map(e => e.horseName).join(', ')}`;
  }

  async setMailchimpMember(){
    const data = {
      email_address: this.email,
      id_concurso: this.showID
    };
    if(this.club_id != Club.splitRock) return console.log('Not a SR show')
    try {
      const response = await firstValueFrom(this._emailsService.setMailchimpMember(this.email, this.showID));
      console.log(response);
      if(response.status == 400){
        console.error('Error setting mailchimp member: ', response.detail);
      } else {
        console.log('Mailchimp member set: ', response);
      }
    } catch (error) {
      console.error('Error: ', error)
    }
  }

  public sendErrorEmail(title: string, error: any, customer: string, php: boolean = false){
    this._emailsService.sendEmail(title + ' for ' + customer, error );
    if(php) this._emailsService.sendEmailPhp(title + ' for ' + customer, error);
  }
}
