declare let $: any;

const errorNotification = (title: string, message: string | string[], position: string = 'bottom-right', loaderBg: string = 'rgba(0,0,0,0.2)', icon: string = 'error', hideAfter: number = 10000) => {
  $.NotificationApp.send(title, message, position, loaderBg, icon, hideAfter);
};

const successNotification = (title: string, message: string, position: string = 'bottom-right', loaderBg: string = 'rgba(0,0,0,0.2)', icon: string = 'success', hideAfter: number = 10000) => {
  $.NotificationApp.send(title, message, position, loaderBg, icon, hideAfter);
};

const warningNotification = (title: string, message: string, position: string = 'bottom-right', loaderBg: string = 'rgba(0,0,0,0.2)', icon: string = 'warning', hideAfter: number = 10000) => {
  $.NotificationApp.send(title, message, position, loaderBg, icon, hideAfter);
};

const infoNotification = (title: string, message: string, position: string = 'bottom-right', loaderBg: string = 'rgba(0,0,0,0.2)', icon: string = 'info', hideAfter: number = 10000) => {
  $.NotificationApp.send(title, message, position, loaderBg, icon, hideAfter);
};

export { errorNotification, successNotification, warningNotification, infoNotification };
