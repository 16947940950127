import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { EntriesService } from "../services/entries.service";
import { AuthService } from "../services/auth.service";

@Component({
  selector: 'app-add-drops',
  templateUrl: './add-drops.component.html',
  styleUrls: ['./add-drops.component.scss']
})
export class AddDropsComponent implements OnInit {
  public email: string = ''; // 'mauricio@vende.io';
  public currentShow: string = '';
  public entries: any = [];
  public nombreConcurso: string;
  public showId: string;
  public loading: boolean;
  public pagoEnProceso: boolean;

  constructor(
    private route: ActivatedRoute,
    private entriesService: EntriesService,
    private router: Router,
    private _authService: AuthService
  ) {
    this.entries = [];
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.nombreConcurso = sessionStorage.getItem('showname')||'';
    this.showId = sessionStorage.getItem('showID')||'';
    this.loading = false;
    this.pagoEnProceso = false;
  };

  async ngOnInit(){
    const user = await this._authService.getLoggedUser();
    // Definir concurso actual antes de traer informacion de entries disponibles
    if(this.showId){
      this.getEntries(this.showId);
    } else{
      this.getConcursoActual();
    }
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
  }

  public getConcursoActual() {
    this.entriesService.getConcursoActual().subscribe((response: any) => {
      this.getEntries(response.show);
    }
    );
  }

  public getEntries(show: any) {
    this.entriesService.getShowEntries(show, this.email).subscribe((response: any) => {
      this.entries = response.binomios;
    });
  }

  public goToEntry(idBinomio: string) {
    this.router.navigate(['/entry/', idBinomio]);
  }
}
