import { Component } from '@angular/core';

@Component({
  selector: 'app-stalls',
  templateUrl: './stalls.component.html',
  styleUrls: ['./stalls.component.scss']
})
export class StallsComponent {
  organizer: number;
  showID: number;
  sr: boolean;
  tc: boolean;

  constructor() {
    this.organizer = 0;
    // Get Session Storage value for club_id
    const clubId = sessionStorage.getItem('club_id');
    const showId = sessionStorage.getItem('showID');
    if (clubId !== null) {
      this.organizer = parseInt(clubId);
    }
    if(showId !== null){
      this.showID = parseInt(showId);
    }
    this.sr = (sessionStorage.getItem('sr')||'0') == '1';
    this.tc = (sessionStorage.getItem('tc')||'0') == '1';
  }




}
