import { Component } from '@angular/core';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})

export class LeftMenuComponent {
  public reining: boolean;
  public tc: boolean;
  public addEntryRoute: string;
  public checkoutRoute: string;
  showID: number;
  public organizer: number;

  constructor() {
    this.organizer = 0;
    this.reining = sessionStorage.getItem('reining') === '1';
    this.tc = sessionStorage.getItem('tc') === '1';
    this.addEntryRoute = this.reining ? '/add-entry' : '/create-entry';
    this.checkoutRoute = this.reining ? '/checkout-reining' : '/checkout';
    // Get Session Storage value for club_id
    const clubId = sessionStorage.getItem('club_id');
    const showId = sessionStorage.getItem('showID');
    if (clubId !== null) {
      this.organizer = parseInt(clubId);
    }
    if(showId !== null){
      this.showID = parseInt(showId);
    }
  }
}
