<div class="navbar-custom">
  <div class="topbar container-fluid">
      <div class="d-flex align-items-center gap-lg-2 gap-1">

          <!-- Topbar Brand Logo -->
          <div class="logo-topbar">
              <!-- Logo light -->
              <a href="index.html" class="logo-light">
                  <span class="logo-lg">
                      <img src="assets/images/logo.png" alt="logo">
                  </span>
                  <span class="logo-sm">
                      <img src="assets/images/logo.png" alt="small logo">
                  </span>
              </a>

              <!-- Logo Dark -->
              <a href="index.html" class="logo-dark">
                  <span class="logo-lg">
                      <img src="assets/images/logo-dark.png" alt="dark logo">
                  </span>
                  <span class="logo-sm">
                      <img src="assets/images/logo-dark.png" alt="small logo">
                  </span>
              </a>
          </div>

          <!-- Sidebar Menu Toggle Button -->
          <button class="button-toggle-menu">
              <i class="mdi mdi-menu"></i>
          </button>

          <!-- Horizontal Menu Toggle Button -->
          <button class="navbar-toggle" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
              <div class="lines">
                  <span></span>
                  <span></span>
                  <span></span>
              </div>
          </button>
      </div>

      <ul class="topbar-menu d-flex align-items-center gap-3">
          <li class="dropdown d-lg-none">
              <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                  <i class="ri-search-line font-22"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
                  <form class="p-3">
                      <input type="search" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                  </form>
              </div>
          </li>

          <li>
            <div class="version nav-user d-flex" style="padding: 0 10px!important; border: 0 !important; background: unset; text-align: center !important;">
              <span class="my-auto"><small>v{{ version }}</small></span>
            </div>
          </li>

          <li class="dropdown notification-list">
            <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
              <i class="ri-shopping-cart-2-fill font-22"></i>
              <span class="noti-icon-badge" *ngIf="drafts.length > 0"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg py-0">
              <a class="dropdown-item text-center text-primary notify-item border-top py-2" *ngFor="let d of drafts">
                Horse: {{ d.horseName }}, Rider: {{ d.riderFirstname }} {{ d .riderLastname }} <br>
                classes: -
              </a>
              <a class="dropdown-item text-center text-primary notify-item border-top py-2" [routerLink]="[checkoutRoute]" routerLinkActive="router-link-active" *ngIf="drafts.length > 0">
                GO TO PAYMENT
              </a>
            </div>
          </li>

          <li class="dropdown">
            <a class="nav-link dropdown-toggle arrow-none nav-user px-2" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                <span class="account-user-avatar">
                    <img src="assets/images/logo-dark.png" alt="user-image" width="32" class="rounded-circle">
                </span>
                <span class="d-lg-flex flex-column gap-1 d-none">
                    <h5 class="my-0">{{ username }}</h5>
                    <h6 class="my-0 fw-normal">{{ email }}</h6>
                    <h6 class="my-0 fw-normal">{{ showName }}</h6>
                </span>
            </a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown" data-popper-placement="bottom-end">
              <div class=" dropdown-header noti-title">
                  <h6 class="text-overflow m-0">Welcome !</h6>
              </div>
              <!--a href="javascript:void(0);" class="dropdown-item">
                  <i class="mdi mdi-account-circle me-1"></i>
                  <span>My Account</span>
              </a-->
              <!--a href="javascript:void(0);" class="dropdown-item">
                  <i class="mdi mdi-account-edit me-1"></i>
                  <span>Settings</span>
              </a-->
              <!--a href="javascript:void(0);" class="dropdown-item">
                  <i class="mdi mdi-lifebuoy me-1"></i>
                  <span>Support</span>
              </a-->
              <a href="javascript:void(0);" class="dropdown-item" routerLink="/select-show">
                  <i class="mdi mdi-swap-horizontal me-1"></i>
                  <span>Change Show</span>
              </a>
              <a href="javascript:void(0);" class="dropdown-item" (click)="logOut()">
                  <i class="mdi mdi-logout me-1"></i>
                  <span>Logout</span>
              </a>
            </div>
          </li>

      </ul>
  </div>
</div>
