import { Component, OnInit } from '@angular/core';
import { EntriesService } from '../services/entries.service';
import { PersonsService } from '../services/persons.service';
import { AuthService } from './../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { errorNotification } from '../services/notifications.service';

@Component({
  selector: 'app-my-entries',
  templateUrl: './my-entries.component.html',
  styleUrls: ['./my-entries.component.scss']
})
export class MyEntriesComponent implements OnInit {
  public drafts: any[];
  public oldEntries: any[];
  public showID: string;
  public showName: string;
  public email: string;
  public reining: boolean;
  public checkoutRoute: string;

  constructor(
    private _personsService: PersonsService,
    private _entriesService: EntriesService,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.drafts = [];
    this.oldEntries = [];
    const routeParams = this.route.snapshot.paramMap;
    this.reining = sessionStorage.getItem('reining') === '1';
    this.checkoutRoute = this.reining ? '/checkout-reining' : '/checkout';
    this.email = localStorage.getItem('email') || (sessionStorage.getItem('email') || '');
    this.showID = sessionStorage.getItem('showID')||'';
    this.showName = sessionStorage.getItem('showname')||'';
  }

  async ngOnInit() {
    const user = await this._authService.getLoggedUser();
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    this.getEntries();
    this.getOldEntries();
  }

  public async getEntries() {
    this._personsService.getDocumentByEmail(this.email).then((response) => {
      this._entriesService.getEntries(response.uid, this.showID).then(d => { this.drafts = d; console.log(d); }).catch(error => console.warn(error))
    }).catch(error => console.warn(error))
  }

  async getOldEntries() {
    const currentShowID: any = 1;

    const response: any = await this._entriesService.getSolitudesPorMail(this.email, currentShowID);
    if(response.error) {
      errorNotification('Error', response.message);
      return;
    }
    this.oldEntries = response.solicitudes||[];
  }
}
