import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { PersonsService } from '../services/persons.service';
import { HorsesService } from '../services/horses.service';
import { EntriesService } from '../services/entries.service';
import { successNotification, errorNotification } from '../services/notifications.service';
import { SignaturePad } from 'angular-signature-pad-v2';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Firestore, deleteDoc, doc } from '@angular/fire/firestore';
declare var $: any;
declare let bootstrap: any;

@Component({
  selector: 'app-add-entry',
  templateUrl: './add-entry.component.html',
  styleUrls: ['./add-entry.component.scss']
})

export class AddEntryComponent implements OnInit, AfterViewInit {
  @ViewChildren(SignaturePad) signaturePads: QueryList<SignaturePad>;
  //@ViewChildren('')
  public horseForm: FormGroup;
  public ownerForm: FormGroup;
  public riderForm: FormGroup;
  public trainerForm: FormGroup;
  public payeeForm: FormGroup;
  public stablingForm: FormGroup;
  public formPayee: FormGroup;
  public taxId: string;
  public termsAndConditions: boolean;
  public discipline: any;
  public emergencyForm: FormGroup;
  public showInfo: any;
  public personLogged: any;
  public personDocument: any;
  public entry: any;
  public conceptos: any[];
  public suggestions: any;
  public selects: any;
  public signaturePadOptions: Object;
  public conceptosSeleccionados: Array<any>;
  public entriesDue: any;
  public entryAmount: number;
  public actions: any;
  public divisions: Array<any>;
  public classCharges: any[];
  public loadingNrha: boolean;
  public loadingFei: boolean;
  //public loadingNRHA: boolean;
  public organizer: string;
  public reining: boolean;
  public uid: string;
  public email: string
  public showID: string;

  constructor(
    private _authService: AuthService,
    private _personsService: PersonsService,
    private _entriesService: EntriesService,
    private _horsesService: HorsesService,
    private _router: Router,
    private _firestore: Firestore
  ) {
    this.discipline = '';
    this.horseForm = new FormGroup({
      nrha: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      yearFoaled: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      sire: new FormControl('', [Validators.required]),
      dam: new FormControl('', [Validators.required]),
    });
    this.ownerForm = new FormGroup({
      nrha: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      phonenumber: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
    this.riderForm = new FormGroup({
      nrha: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      phonenumber: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
    this.trainerForm = new FormGroup({
      nrha: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      phonenumber: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
    this.stablingForm = new FormGroup({
      with: new FormControl('', [])
    });
    this.emergencyForm = new FormGroup({
      fullname: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required])
    });
    this.payeeForm = new FormGroup({
      nrha: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      phonenumber: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
    this.entry = {
      classes: []
    };
    this.showInfo = {};
    this.suggestions = {
      horse: [],
      trainer: [],
      owner: [],
      rider: [],
      payee: []
    }
    this.divisions = [];
    this.actions = {
      modalForm: '',
    }
    this.conceptos = [];
    this.conceptosSeleccionados = [];
    this.personLogged = {};
    this.entryAmount = 0;
    this.selects = {
      horse: '',
      trainer: '',
      owner: '',
      payee: '',
      rider: '',
      riders: []
    }
    this.termsAndConditions = false;
    this.taxId = '';
    this.signaturePadOptions = { // passed through to szimek/signature_pad constructor
      dotSize: 1,
      minWidth: 1,
      maxWidth: 1,
      backgroundColor: 'rgb(245, 245, 245)'
    };
    this.classCharges = [];
    this.loadingNrha = false;
    this.loadingFei = false;
    this.reining = sessionStorage.getItem('reining') === '1';
    this.uid = localStorage.getItem('user_document_id')||(sessionStorage.getItem('user_document_id')||'');
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.showID = sessionStorage.getItem('showID')||'';
  }

  async ngOnInit(){
    const user = await this._authService.getLoggedUser();
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    if(!this.uid){
      this._personsService.getDocumentByEmail(this.email).then((doc: any) => {
        this.uid = doc.uid;
        localStorage.setItem('user_document_id', this.uid);
        console.log('User document id: ', this.uid);
      });
    }
    await this.getCurrentShow();
    if(this.reining){
      this.discipline = 'all';
      const huntersDivisions = await this.getDivisionsByType('hunters');
      const jumpersDivisions = await this.getDivisionsByType('jumpers');

      this.divisions = [...huntersDivisions, ...jumpersDivisions]
    }
  }

  ngAfterViewInit(): void {
    this.signaturePads.changes.subscribe(pads => {
      for(const sinaturePad of pads.toArray()){

      }
      this.resizeCanvas();
    });
    this.resizeCanvas();
    const popoverTriggerList: any = document.querySelectorAll('[data-bs-toggle="popover"]')
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl,{
      html: true
    }));
    $(document).delegate('a.remove-trainer-sugestion', 'click', (e: any) => {
      this.removeTrainerSuggestion(e.currentTarget.id);
    });
    $(document).delegate('a.remove-rider-sugestion', 'click', (e: any) => {
      this.removeRiderSuggestion(e.currentTarget.id);
    });
    $(document).delegate('a.remove-owner-sugestion', 'click', (e: any) => {
      this.removeOwnerSuggestion(e.currentTarget.id);
    });
    $(document).delegate('a.remove-horse-sugestion', 'click', (e: any) => {
      this.removeHorseSuggestion(e.currentTarget.id);
    });
  }

  async removeTrainerSuggestion(uid: string){
    const docRef = doc(this._firestore, 'users', this.uid, 'trainerSuggestions', uid);
    await deleteDoc(docRef);
    this.getPersonSuggestions('trainer');
  }

  async removeRiderSuggestion(uid: string){
    const docRef = doc(this._firestore, 'users', this.uid, 'riderSuggestions', uid);
    await deleteDoc(docRef);
    this.getPersonSuggestions('rider');
  }

  async removeOwnerSuggestion(uid: string){
    const docRef = doc(this._firestore, 'users', this.uid, 'ownerSuggestions', uid);
    await deleteDoc(docRef);
    this.getPersonSuggestions('owner');
  }

  async removeHorseSuggestion(uid: string){
    const docRef = doc(this._firestore, 'users', this.uid, 'horseSuggestions', uid);
    await deleteDoc(docRef);
    this.getHorsesSuggestions();
  }

  public addRider(e: any) {
    const rider = this.suggestions.rider.find((rider: any) => rider.uid === e);
    this.selects.riders.push(rider);
    this.suggestions.rider.splice(rider, 1);
    this.selects.rider = '';
  }

  public async getCurrentShow() {
    if (!this.showID) {
      this._router.navigate(['/select-show']);
      return;
    }
    const response: any = await this._entriesService.getEntryInfo(this.showID);
    if(response.error){
      errorNotification('Error', response.message);
      return
    }
    this.entryAmount = response.entryAmount;
    //this.showInfo.entryAmount = response.entryAmount;
    this.showInfo.entriesDue = response.entriesDue;
    this.showInfo.cardPayment = response.cardPayment;
    this.showInfo.checkPayment = response.checkPayment;
    this.showInfo.square_config = response.square_config || null;
    this.showInfo.showID = sessionStorage.getItem('showID')||'';
    this.organizer = response.id_club;
    this.getPersonLogged();
    this.getConceptos();

    return response;
  }

  public async getPersonLogged() {
    const response = await this._personsService.getByEmail(this.email, this.showID);
    if (response.error) {
      errorNotification('Error', response.message);
      return;
    }
    if (!response.error) this.personLogged = response.entrenador;
    try {
      this.personDocument = await this._personsService.getDocumentByEmail(this.email);
    } catch (reason: any) {
      errorNotification(reason.error.code, `Error getting person logged. ${reason.error.message}`);
      return;
    }
    this.getHorsesSuggestions();
    this.getPersonSuggestions('payee');
    this.getPersonSuggestions('owner');
    this.getPersonSuggestions('trainer');
    this.getPersonSuggestions('rider');
  }

  public async getHorsesSuggestions(select?: any) {
    try {
      this.suggestions['horse'] = await this._horsesService.getHorsesSuggestions(this.personDocument.uid);
    } catch (reason: any) {
      errorNotification(reason.error.code, `Error getting horses suggestions. ${reason.error.message}`);
      return;
    }
    const popoverHorses = bootstrap.Popover.getOrCreateInstance('#popover-horses') // Returns a Bootstrap popover
    popoverHorses.setContent({
      '.popover-header': 'Horse Suggestions',
      '.popover-body': `<ul class="list-group">
      ${this.suggestions['horse'].map((t: any) => `<li class="list-group-item d-flex justify-content-between align-items-center"><span>${t.name}</span><a href="javascript:;" class="remove-horse-sugestion" id="${t.uid}"><i class="mdi mdi-trash-can text-danger"></i></a></li>`).join('')}
  </ul>`
    });
    if (select) {
      this.selects.horse = select;
      $('#formModal').modal('hide');
    }
  }

  public async getPersonSuggestions(type: string, select?: any) {
    try {
      this.suggestions[type] = await this._personsService.getPersonsSuggestions(this.personDocument.uid, type);
      this.suggestions[type] = (this.suggestions[type] || []).map((s: any) => ({ ...s, address: s.address || '', address2: s.address2 || '', city: s.city || '', state: s.state || '', zip: s.zip || '', country: s.country || '' }));
    } catch (reason: any){
      errorNotification(reason.error.code, `Error getting person suggestions. ${reason.error.message}`);
      return;
    }
    if(select){
      this.selects[type] = select;
      $('#formModal').modal('hide');
    }

    switch (type) {
      case 'trainer':
        const popoverTrainers = bootstrap.Popover.getOrCreateInstance('#popover-trainers') // Returns a Bootstrap popover
        popoverTrainers.setContent({
          '.popover-header': 'Trainer Suggestions',
          '.popover-body': `<ul class="list-group">
          ${this.suggestions['trainer'].map((t: any) => `<li class="list-group-item d-flex justify-content-between align-items-center"><span>${t.firstname} ${t.lastname}</span><a href="javascript:;" class="remove-trainer-sugestion" id="${t.uid}"><i class="mdi mdi-trash-can text-danger"></i></a></li>`).join('')}
      </ul>`
        });
        break;
      case 'rider':
        const popoverRiders = bootstrap.Popover.getOrCreateInstance('#popover-riders') // Returns a Bootstrap popover
        popoverRiders.setContent({
          '.popover-header': 'Rider Suggestions',
          '.popover-body': `<ul class="list-group">
          ${this.suggestions['rider'].map((t: any) => `<li class="list-group-item d-flex justify-content-between align-items-center"><span>${t.firstname} ${t.lastname}</span><a href="javascript:;" class="remove-rider-sugestion" id="${t.uid}"><i class="mdi mdi-trash-can text-danger"></i></a></li>`).join('')}
      </ul>`
        });
        break;
      case 'owner':
        const popoverOwners = bootstrap.Popover.getOrCreateInstance('#popover-owners') // Returns a Bootstrap popover
        popoverOwners.setContent({
          '.popover-header': 'Owner Suggestions',
          '.popover-body': `<ul class="list-group">
          ${this.suggestions['owner'].map((t: any) => `<li class="list-group-item d-flex justify-content-between align-items-center"><span>${t.firstname} ${t.lastname}</span><a href="javascript:;" class="remove-owner-sugestion" id="${t.uid}"><i class="mdi mdi-trash-can text-danger"></i></a></li>`).join('')}
      </ul>`
        });
        break;
      default:
        break;
    }
  }

  private setNrhaLoader(loading: boolean) {
    this.loadingNrha = loading;
    if(loading){
      this.horseForm.disable();
      this.ownerForm.disable();
      this.riderForm.disable();
      this.trainerForm.disable();
      this.payeeForm.disable();
    } else{
      this.horseForm.enable();
      this.ownerForm.enable();
      this.riderForm.enable();
      this.trainerForm.enable();
      this.payeeForm.enable();
    }
  }

  private setFeiLoader(loading: boolean) {
    this.loadingFei = loading;
    if(loading){
      this.horseForm.disable();
      this.ownerForm.disable();
      this.riderForm.disable();
      this.trainerForm.disable();
      this.payeeForm.disable();
    } else{
      this.horseForm.enable();
      this.ownerForm.enable();
      this.riderForm.enable();
      this.trainerForm.enable();
      this.payeeForm.enable();
    }
  }

  public async getHorseByNrha() {
    if (this.horseForm.value.nrha == '') return;
    this.setNrhaLoader(true);
    const response: any = await firstValueFrom(this._horsesService.getHorseByNrha(this.horseForm.value.nrha)).then(r => r||{}, r => r||{});
    if (response.error) {
      errorNotification('Error', response.message);
      this.setNrhaLoader(false);
      return;
    }
    const [foalDate] = (response.foalDate||'').split('-');
    const value = {
      name: response.horseName||this.horseForm.value.name,
      yearFoaled: foalDate||this.horseForm.value.yearFoaled,
      gender: (response.sex||this.horseForm.value.gender).toLowerCase(),
      sire: response.sireName||this.horseForm.value.sire,
      dam: response.damName||this.horseForm.value.dam
    };
    this.horseForm.patchValue(value);
    this.entry.horseInfo = value;
    this.setNrhaLoader(false);
  }

  public async getPersonByNrha(nrha: string, type: string) {
    if (!nrha) return;
    this.setNrhaLoader(true);
    try {
      const [response]: any = await firstValueFrom(this._personsService.getByNrha(nrha));
      if (response.error) {
        errorNotification('Error', response.message);
        this.setNrhaLoader(false);
        return;
      }
      const value: any = {
        nrha: nrha,
        firstname: response.firstName || '',
        lastname: response.lastName || '',
        city: response.city || '',
        state: response.state || '',
        zip: response.zip || '',
        country: response.country || '',
        phonenumber: response.phoneNumber || '',
      };
      if (type == 'payee') {
        this.payeeForm.patchValue(value);
        this.entry.payeeInfo = value;
      } else if (type == 'rider') {
        this.riderForm.patchValue(value);
        this.entry.riderInfo = value;
      } else if (type == 'owner') {
        this.ownerForm.patchValue(value);
        this.entry.ownerInfo = value;
      } else if (type == 'trainer') {
        this.trainerForm.patchValue(value);
        this.entry.trainerInfo = value;
      }
      this.setNrhaLoader(false);
    } catch (reason: any) {
      errorNotification('Error', reason);
      this.setNrhaLoader(false);
      return;
    }
  }

  public differentPayee(event: any) {
    this.actions.showPayeeForm = event.target.checked;
  }

  public async getConceptos() {
    const response: any = await this._entriesService.getConceptos(this.showInfo.showID);
    if (response.error) {
      errorNotification('Error', response.message);
      return;
    }
    this.conceptos = response.conceptos;
  }

  public async getDivisionsByType(discipline = this.discipline) {
    const response = await firstValueFrom(this._entriesService.getDivisionsByType(this.showInfo.showID, discipline));
    if (response.error) {
      //errorNotification('Error', response.message);
      return [];
    }

    let divisions = [];
    if(discipline == 'FEI_jumpers'){
      divisions = response.divisions.filter((division: any) => division.name.includes('FEI'));
      console.log('FEI Divisions: ', this.divisions);
    } else if(discipline == 'jumpers'){
      divisions = response.divisions.filter((division: any) => !division.name.includes('FEI'));
      console.log('Jumper Divisions: ', this.divisions);
    } else {
      divisions = response.divisions;
      console.log('Other Divisions: ', this.divisions);
    }
    return divisions||[];
  }

  public agregarCargo(idConcepto: any, monto: any, nombre: any, event: any) {
    const qty = isNaN(event.target.valueAsNumber) ? 0 : event.target.valueAsNumber;
    const index = this.conceptosSeleccionados.findIndex(concepto => {
      return concepto.id == idConcepto;
    });
    if (qty == 0) { //Si es 0 borrar de los conceptos seleccionados
      if (index > -1) { //Ya existe en el array conceptos seleccionados
        this.conceptosSeleccionados.splice(index, 1);
      } else {
        //No existe entonces no hay que borrar nada
      }
    } else {
      if (index > -1) { //Ya existe en el array conceptos seleccionados hay que editarlo
        this.conceptosSeleccionados[index].quantity = qty;
        this.conceptosSeleccionados[index].total = monto * qty;
      } else { //Aun no existe en el array hay que agregarlo
        this.conceptosSeleccionados.push({ id: idConcepto, amount: monto, name: nombre, quantity: qty, total: monto * qty });
      }
    }
    console.log('Conceptos seleccionados: ', this.conceptosSeleccionados);
  }

  public totalCargos(): number {
    const divisionFee = this.classCharges.reduce((a, b) => a + parseFloat(b.amount||'0'), 0);
    const charges = this.conceptosSeleccionados.reduce((a, b) => a + parseFloat(b.total||'0'), 0);
    /*return this.conceptosSeleccionados.reduce((a, b) => {
      return a + b.total;
    }, 0);*/
    return divisionFee + charges;
  }

  public addClassToEntry(division: any, prueba: any, add: any){
    //Add class to entry
    if(add){
      this.entry.classes.push(prueba);
    } else{
      const index = this.entry.classes.findIndex((c: any) => c.ipc == prueba.ipc);
      if(index > -1){
        this.entry.classes.splice(index, 1);
      }
    }

    //Calculate division fee
    //Add division fee
    if(division.charged_by == 'division-fee' && add){
      console.log('division-fee');
      //Validate if charge is already in the entry
      let charge = this.classCharges.find((c: any) => c.type == 'd' && c.reference == division.id);
      if(!charge){
        charge = {
          reference: division.id,
          name: `${division.code} ${division.name}`,
          show_id: this.showInfo.showID,
          amount: division.cost,
          type: 'd'
        };
        this.classCharges.push(charge);
      }
    } else if(division.charged_by == 'division-fee' && !add){
      //Validate if has more classes in the division
      const charges = this.entry.classes.filter((c: any) => c.division == division.id);
      if(charges.length == 0){
        //Remove division fee
        const index = this.classCharges.findIndex((c: any) => c.type == 'd' && c.reference == division.id);
        this.classCharges.splice(index, 1);
      }
    } else if(division.charged_by == 'class-fees' && add){
      //Add class fee
      this.classCharges.push({
        reference: prueba.ipc,
        name: `${prueba.numero} ${prueba.nombre}`,
        show_id: this.showInfo.showID,
        amount: prueba.costo,
        type: 'i'
      });
    } else if(division.charged_by == 'class-fees' && !add){
      //Remove class fee
      const index = this.classCharges.findIndex((c: any) => c.type == 'i' && c.reference == prueba.ipc);
      this.classCharges.splice(index, 1);
    }

    //Charges assigned to current class
    const classCharges = JSON.parse(prueba.charges||'{}');
    //Charges assigned to all classes (ids)
    const allCharges = this.entry.classes.reduce((acc: any, curr: any) => [...acc, ...JSON.parse(curr.charges||'{}')], []).map((c: any) => c.id);
    const divisionJudgesFees = division.pruebas.reduce((acc: any, curr: any) => [...acc, ...JSON.parse(curr.charges||'{}')], []).filter((c: any) => c.nombre.includes('Judges Fee'));
    /*const classesByDivision = this.conceptos.filter((c: any) => c.show_id == this.showInfo.showID && c.type == 'c' && c.division == division.id);
    console.log(allChargesCurrentDivision.filter((c: any) => c.nombre.includes('Judges Fee')));*/
    //Add or remove charges assigned to class
    for(const charge of classCharges){
      if(add && !this.conceptosSeleccionados.find((c: any) => c.id == charge.id)){
        //Class/Division added and charge doesn't exist in the entry
        this.conceptosSeleccionados.push({
          id: charge.id,
          amount: charge.monto_unitario,
          name: charge.nombre,
          quantity: '1',
          total: charge.monto_unitario,
        });
      } else if(!add){
        //Validate to remove charge assigned to class
        if(!allCharges.includes(charge.id)){
          const indexCargoAEliminar = this.conceptosSeleccionados.findIndex((c: any) => c.id == charge.id);
          this.conceptosSeleccionados.splice(indexCargoAEliminar, 1);
        }
      }
    }

    //Judges fees
    //Remove all judges fees assigned to the division
    this.conceptosSeleccionados = this.conceptosSeleccionados.filter((c: any) => !divisionJudgesFees.map((c: any) => c.id).includes(c.id));
    //Add epensive  judges fees assigned to the division
    const [expensiveJudgesFee] = divisionJudgesFees.filter((c: any) => allCharges.includes(c.id)).sort((a: any, b: any) => {
      if(parseFloat(a.monto_unitario||'0') == parseFloat(b.monto_unitario||'0')) return 0;
      return parseFloat(a.monto_unitario||'0') > parseFloat(b.monto_unitario||'0') ? -1 : 1;
    });
    if(expensiveJudgesFee){
      this.conceptosSeleccionados.push({
        id: expensiveJudgesFee.id,
        amount: expensiveJudgesFee.monto_unitario,
        name: expensiveJudgesFee.nombre,
        quantity: '1',
        total: expensiveJudgesFee.monto_unitario,
      });
    }
    /*let index = this.entry.classes.findIndex((e: any) => e === ipc);
    (index == -1) ? this.entry.classes.push(ipc) : this.entry.classes.splice(index, 1);*/
  }

  public async save() {
    $('form.needs-validation').addClass('was-validated');
    if (this.selects.trainer == '') {
      errorNotification('Error', 'Trainer has not been selected');
      return;
    }
    if (this.selects.horse == '') {
      errorNotification('Error', 'horse has not been selected');
      return;
    }
    if (this.selects.owner == '') {
      errorNotification('Error', 'Owner has not been selected');
      return;
    }
    if (this.selects.riders.length == 0) {
      errorNotification('Error', 'Rider has not been selected');
      return;
    }
    if (this.selects.payee == '' && this.actions.showPayeeForm) {
      errorNotification('Error', 'payee has not been selected');
      return;
    }
    if (!this.emergencyForm.valid) {
      errorNotification('Error', 'Emergency form incomplete');
      return;
    }
    if (this.discipline == '') {
      errorNotification('Error', 'Discipline field empty');
      return;
    }
    if (!this.termsAndConditions) {
      errorNotification('Error', 'termsAndConditions checkbox not selected');
      return;
    }

    //Validar que las firmas esten completas
    let signaturesValid = true
    for(const sinaturePad of this.signaturePads.toArray()){
      if(sinaturePad.isEmpty()) signaturesValid = false;
    }
    if (!signaturesValid) {
      errorNotification('Error', 'A signature is missing');
      return;
    }

    for(const sinaturePad of this.signaturePads.toArray()){
      sinaturePad.off();
    }

    let [rider] = this.selects.riders;
    const riders = this.selects.riders;
    const owner = this.suggestions.owner.find((e: any) => e.uid == this.selects.owner);
    let payee = owner;
    //El payee por defecto es el dueño a menos que se indique que es diferente en el checkbox
    if (this.actions.showPayeeForm) {
      payee = this.suggestions.payee.find((e: any) => e.uid == this.selects.payee);
    }
    const trainer = this.suggestions.trainer.find((e: any) => e.uid == this.selects.trainer);
    const horse = this.suggestions.horse.find((e: any) => e.uid == this.selects.horse);

    //SignatureTrainer
    const [signatureTrainer] = this.signaturePads.toArray().filter((sp: any) => sp.elementRef.nativeElement.classList.contains('trainer'));
    //SignatureOwner
    const [signatureOwner] = this.signaturePads.toArray().filter((sp: any) => sp.elementRef.nativeElement.classList.contains('owner'));

    let document = {
      riderNrha: rider.nrha || '',
      riderFEI: '',
      riderFirstname: rider.firstname || '',
      riderLastname: rider.lastname || '',
      riderAddress: rider.address || '',
      riderAddress2: rider.address2 || '',
      riderPhonenumber: rider.phonenumber || '',
      riderCity: rider.city || '',
      riderState: rider.state || '',
      riderZip: rider.zip || '',
      riderCountry: rider.country || '',
      //signatureRider: this.signaturePads.toArray().find((sp: any) => sp.elementRef.nativeElement.id == rider.uid)!.toDataURL('image/svg+xml'),
      riders: riders.map(
        (r: any) => ({
          ...r,
          riderAddress: r.address || '',
          riderAddress2: r.address2 || '',
          riderCity: r.city || '',
          riderState: r.state || '',
          riderZip: r.zip || '',
          riderCountry: r.country || '',
          //signatureRider: this.signaturePads.toArray().find((sp: any) => sp.elementRef.nativeElement.id == r.uid)!.toDataURL('image/svg+xml')
      })),
      payeeNrha: payee.nrha || owner.nrha || '',
      payeename: payee.firstname || owner.firstname || '',
      payeeLastname: payee.lastname || owner.lastname || '',
      payeeAddress: payee.address || owner.address || '',
      payeeAddress2: payee.address2 || owner.address2 || '',
      payeePhonenumber: payee.phonenumber || owner.phonenumber || '',
      payeeCity: payee.city || owner.city || '',
      payeeState: payee.state || owner.state || '',
      payeeZip: payee.zip || owner.zip || '',
      payeeCountry: payee.country || owner.country || '',
      ownerNrha: owner.nrha || '',
      ownerFEI: '',
      ownername: owner.firstname || '',
      ownerLastname: owner.lastname || '',
      ownerAddress: owner.address || '',
      ownerAddress2: owner.address2 || '',
      ownerPhonenumber: owner.phonenumber || '',
      ownerCity: owner.city || '',
      ownerState: owner.state || '',
      ownerZip: owner.zip || '',
      ownerCountry: owner.country || '',
      trainerNrha: trainer.nrha || '',
      trainername: trainer.firstname || '',
      trainerLastname: trainer.lastname || '',
      trainerAddress: trainer.address || '',
      trainerAddress2: trainer.address2 || '',
      trainerPhonenumber: trainer.phonenumber || '',
      trainerCity: trainer.city || '',
      trainerState: trainer.state || '',
      trainerZip: trainer.zip || '',
      trainerCountry: trainer.country || '',
      horseNrha: horse.nrha || '',
      horseFEI: '',
      horseName: horse.name || '',
      emergencyContact: this.emergencyForm.value.fullname || '',
      emergencyPhone: this.emergencyForm.value.phone || '',
      stablingWith: this.stablingForm.value.with || '',
      discipline: this.discipline || '',
      conceptos: this.conceptosSeleccionados || '',
      taxID: this.taxId || '',
      user: this.personLogged || '',
      email: this.email||'',
      showID: this.showID||'',
      //signatureTrainer: signatureTrainer.toDataURL('image/svg+xml') || '',
      //signatureOwner: signatureOwner.toDataURL('image/svg+xml') || '',
      status: 'draft',
      paymentReceived: false,
      termsAndConditions: this.termsAndConditions,
      pruebas: this.entry.classes || [],
      classCharges: this.classCharges || [],
      balance: this.totalCargos(),
    }

    const response = await this._entriesService.saveEntryFirebase(document, this.personDocument.uid, this.taxId).then(r => r, r => r);
    if (response.error) {
      errorNotification(response.error.code, response.error);
      return;
    }

    for(const sinaturePad of this.signaturePads.toArray()){
      sinaturePad.clear();
      sinaturePad.on();
    }
    this.emergencyForm.reset();
    this.stablingForm.reset();

    this._router.navigate(['/']);
    successNotification('Registration saved', 'Your registration has been saved successfully');
  }

  public async saveSuggestions(e: any) {
    const address1 = document.querySelector('#address1') as HTMLInputElement;
    e.target.classList.add('was-validated');
    if (this.actions.modalForm == 'horse') {
      const horse = {
        nrha: this.horseForm.value.nrha || '',
        name: this.horseForm.value.name || '',
        yearFoaled: this.horseForm.value.yearFoaled || '',
        gender: this.horseForm.value.gender || '',
        sire: this.horseForm.value.sire || '',
        dam: this.horseForm.value.dam || '',
      };
      if (horse.nrha) {
        const horseSuggestion = this.suggestions['horse'].find((h: any) => horse.nrha == h.nrha);
        if (horseSuggestion) {
          this.getHorsesSuggestions(horseSuggestion.uid);
          return;
        }
      }

      if (!this.horseForm.valid) return;

      const { documentId, reason } = await this._horsesService.setHorseSuggestion(this.personDocument.uid, horse).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      this.getHorsesSuggestions(documentId);

    } else if (this.actions.modalForm == 'owner') {
      this.ownerForm.get('address')?.setValue(address1.value);
      const owner = {
        nrha: this.ownerForm.value.nrha || '',
        firstname: this.ownerForm.value.firstname || '',
        lastname: this.ownerForm.value.lastname || '',
        address: this.ownerForm.value.address || '',
        address2: this.ownerForm.value.address2 || '',
        phonenumber: this.ownerForm.value.phonenumber || '',
        city: this.ownerForm.value.city || '',
        state: this.ownerForm.value.state || '',
        zip: this.ownerForm.value.zip || '',
        country: this.ownerForm.value.country || ''
      };
      if(owner.nrha){
        const ownerSuggestion = this.suggestions['owner'].find((o: any) => owner.nrha == o.nrha);
        if(ownerSuggestion){
          this.getPersonSuggestions('owner', ownerSuggestion.uid);
          return;
        }
      }

      if (!this.ownerForm.valid) return;

      const { documentId, reason } = await this._personsService.setPersonsSuggestion(this.personDocument.uid, 'ownerSuggestions', owner).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      this.getPersonSuggestions('owner', documentId);
    } else if (this.actions.modalForm == 'rider') {
      this.riderForm.get('address')?.setValue(address1.value);
      const rider = {
        nrha: this.riderForm.value.nrha || '',
        firstname: this.riderForm.value.firstname || '',
        lastname: this.riderForm.value.lastname || '',
        address: this.riderForm.value.address || '',
        address2: this.riderForm.value.address2 || '',
        phonenumber: this.riderForm.value.phonenumber || '',
        city: this.riderForm.value.city || '',
        state: this.riderForm.value.state || '',
        zip: this.riderForm.value.zip || '',
        country: this.riderForm.value.country || ''
      };
      if (rider.nrha) {
        const riderSuggestion = this.suggestions['rider'].find((r: any) => rider.nrha == r.nrha);
        if (riderSuggestion) {
          this.getPersonSuggestions('rider', riderSuggestion.uid);
          return;
        }
      }

      if (!this.riderForm.valid) return;

      const { documentId, reason } = await this._personsService.setPersonsSuggestion(this.personDocument.uid, 'riderSuggestions', rider).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      await this.getPersonSuggestions('rider', documentId);
      this.addRider(documentId);
    } else if (this.actions.modalForm == 'trainer') {
      this.trainerForm.get('address')?.setValue(address1.value);
      const trainer = {
        nrha: this.trainerForm.value.nrha || '',
        firstname: this.trainerForm.value.firstname || '',
        lastname: this.trainerForm.value.lastname || '',
        address: this.trainerForm.value.address || '',
        address2: this.trainerForm.value.address2 || '',
        phonenumber: this.trainerForm.value.phonenumber || '',
        city: this.trainerForm.value.city || '',
        state: this.trainerForm.value.state || '',
        zip: this.trainerForm.value.zip || '',
        country: this.trainerForm.value.country || ''
      };
      if (trainer.nrha) {
        const trainerSuggestion = this.suggestions['trainer'].find((t: any) => trainer.nrha == t.nrha);
        if (trainerSuggestion) {
          this.getPersonSuggestions('trainer', trainerSuggestion.uid);
          return;
        }
      }
      if (!this.trainerForm.valid) return;

      const { documentId, reason } = await this._personsService.setPersonsSuggestion(this.personDocument.uid, 'trainerSuggestions', trainer).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      this.getPersonSuggestions('trainer', documentId);
    } else if (this.actions.modalForm == 'payee'){
      this.payeeForm.get('address')?.setValue(address1.value);
      const payee = {
        nrha: this.payeeForm.value.nrha || '',
        firstname: this.payeeForm.value.firstname || '',
        lastname: this.payeeForm.value.lastname || '',
        address: this.payeeForm.value.address || '',
        address2: this.payeeForm.value.address2 || '',
        phonenumber: this.payeeForm.value.phonenumber || '',
        city: this.payeeForm.value.city || '',
        state: this.payeeForm.value.state || '',
        zip: this.payeeForm.value.zip || '',
        country: this.payeeForm.value.country || ''
      };

      if (payee.nrha) {
        const payeeSuggestion = this.suggestions['payee'].find((t: any) => payee.nrha == t.nrha);
        if (payeeSuggestion) {
          this.getPersonSuggestions('payee', payeeSuggestion.uid);
          return;
        }
      }
      if (!this.payeeForm.valid) return;

      const { documentId, reason } = await this._personsService.setPersonsSuggestion(this.personDocument.uid, 'payeeSuggestions', payee).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      this.getPersonSuggestions('payee', documentId);
    } else {
      return;
    }
  }

  public newEntity(entity: string) {
    this.trainerForm.reset();
    this.riderForm.reset();
    this.ownerForm.reset();
    this.payeeForm.reset();
    this.horseForm.reset();
    this.horseForm.setValue({
      nrha: '',
      name: '',
      yearFoaled: '',
      gender: '',
      sire: '',
      dam: '',
    })
    this.actions.modalForm = entity;
    $('form').removeClass('was-validated');
    $('#formModal').modal('show');
  }

  /**
   * Esta funcion se ejecuta al cambiar el valor del checkbox de terms and conditions pero no hace nada como tal
   */
  TermsAndConditionsAggrement() {
    //Boolean this.termsAndConditions
  }

  /**
   * Funcion que se ejecuta al comezar a dibujar en el canvas de firma
   */
  drawStart(entity: string) {

  }

  /**
   * Funcion que se ejecuta al terminar de dibujar en el canvas de firma
   */
  drawComplete(entity: string) {

  }

  /**
   * Funcion que se ejecuta al limpiar el canvas de firma
   */
  clearDraw(entity: string, riderId: string = '') {
    if (entity == 'rider'){
      const signaturePadsRider = this.signaturePads.toArray().filter((sp: any) => sp.elementRef.nativeElement.classList.contains('rider') && sp.elementRef.nativeElement.id == riderId);
      for(const sinaturePad of signaturePadsRider){
        sinaturePad.clear();
      }
    }
    if (entity == 'trainer') {
      const signaturePadsTrainer = this.signaturePads.toArray().filter((sp: any) => sp.elementRef.nativeElement.classList.contains('trainer'));
      for(const sinaturePad of signaturePadsTrainer){
        sinaturePad.clear();
      }
    }
    if (entity == 'owner'){
      const signaturePadsOwner = this.signaturePads.toArray().filter((sp: any) => sp.elementRef.nativeElement.classList.contains('owner'));
      for(const sinaturePad of signaturePadsOwner){
        sinaturePad.clear();
      }
    }
  }

  showModal() { $('#agreementModal').modal('show'); }

  hideModal() { $('#agreementModal').modal('hide'); }

  resizeCanvas() {
    const offsetWidth = document.querySelector("signature-pad")?.parentElement?.offsetWidth||0;
    console.log(offsetWidth);
    for(const sinaturePad of this.signaturePads.toArray() as any[]){
      sinaturePad.elementRef.nativeElement.querySelector("canvas").width = offsetWidth;
      sinaturePad.clear();
    }
   /* const wrapperTrainer = document.getElementById("wrapperTrainer");
    const canvasTrainer: any = wrapperTrainer?.querySelector("canvas");
    canvasTrainer.width = wrapperTrainer?.offsetWidth;

    const wrapperOwner = document.getElementById("wrapperOwner");
    const canvasOwner: any = wrapperOwner?.querySelector("canvas");
    canvasOwner.width = wrapperOwner?.offsetWidth;*/


  }

  public getAddress(place: any, entity: string) {
    if (entity == 'rider') {
      if (!this.riderForm.value.address) this.riderForm.get('address')?.setValue(place.address);
      if (!this.riderForm.value.address2) this.riderForm.get('address2')?.setValue(place.address2);
      if (!this.riderForm.value.city) this.riderForm.get('city')?.setValue(place.city);
      if (!this.riderForm.value.state) this.riderForm.get('state')?.setValue(place.state);
      if (!this.riderForm.value.zip) this.riderForm.get('zip')?.setValue(place.zip);
      if (!this.riderForm.value.country) this.riderForm.get('country')?.setValue(place.country);
    } else if (entity == 'trainer') {
      if (!this.trainerForm.value.address) this.trainerForm.get('address')?.setValue(place.address);
      if (!this.trainerForm.value.address2) this.trainerForm.get('address2')?.setValue(place.address2);
      if (!this.trainerForm.value.city) this.trainerForm.get('city')?.setValue(place.city);
      if (!this.trainerForm.value.state) this.trainerForm.get('state')?.setValue(place.state);
      if (!this.trainerForm.value.zip) this.trainerForm.get('zip')?.setValue(place.zip);
      if (!this.trainerForm.value.country) this.trainerForm.get('country')?.setValue(place.country);
    } else if (entity == 'owner') {
      if (!this.ownerForm.value.address) this.ownerForm.get('address')?.setValue(place.address);
      if (!this.ownerForm.value.address2) this.ownerForm.get('address2')?.setValue(place.address2);
      if (!this.ownerForm.value.city) this.ownerForm.get('city')?.setValue(place.city);
      if (!this.ownerForm.value.state) this.ownerForm.get('state')?.setValue(place.state);
      if (!this.ownerForm.value.zip) this.ownerForm.get('zip')?.setValue(place.zip);
      if (!this.ownerForm.value.country) this.ownerForm.get('country')?.setValue(place.country);
    } else if (entity == 'payee') {
      if (!this.payeeForm.value.address) this.payeeForm.get('address')?.setValue(place.address);
      if (!this.payeeForm.value.address2) this.payeeForm.get('address2')?.setValue(place.address2);
      if (!this.payeeForm.value.city) this.payeeForm.get('city')?.setValue(place.city);
      if (!this.payeeForm.value.state) this.payeeForm.get('state')?.setValue(place.state);
      if (!this.payeeForm.value.zip) this.payeeForm.get('zip')?.setValue(place.zip);
      if (!this.payeeForm.value.country) this.payeeForm.get('country')?.setValue(place.country);
    }
  }

  public removeCharge(type: 'charge'|'class', element: any){
    if(type == 'charge'){
      const classCharges = this.entry.classes.reduce((a: any, b: any) => {
        const charges = JSON.parse(b.charges||'{}').map((c: any) => ({...c, number: b.numero}));
        return [...a, ...charges];
      },[]);
      const linkedClasses = classCharges.filter((c: any) => c.id == element.id);
      if(linkedClasses.length > 0){
        const message = ['It was not possible to eliminate this charge because it is linked to the following classes:', ...linkedClasses.map((c: any) => c.number)];
        errorNotification('Error', message);
        return;
      }
      const index = this.conceptosSeleccionados.findIndex((c: any) => c.id == element.id);
      this.conceptosSeleccionados.splice(index, 1);
      return;
    } else if(type == 'class') {
      if(element.type == 'i'){
        const indexClass = this.entry.classes.findIndex((c: any) => c.ipc == element.reference);
        const indexCharge = this.classCharges.findIndex((c: any) => c.type == 'i' && c.reference == element.reference);
        this.entry.classes.splice(indexClass, 1);
        this.classCharges.splice(indexCharge, 1);
        document.querySelector<HTMLInputElement>(`input[type="checkbox"][ipc="${element.reference}"]`)!.checked = false;
      } else if(element.type == 'd'){
        const divisionClasses = this.entry.classes.filter((c: any) => c.division == element.reference).map((c: any) => ({ ipc: c.ipc, index: this.entry.classes.findIndex((cl: any) => cl.ipc == c.ipc) }));
        const divisionCharge = this.classCharges.findIndex((c: any) => c.type == 'd' && c.reference  == c.reference);
        this.classCharges.splice(divisionCharge, 1);
        for(const dc of divisionClasses){
          this.entry.classes.splice(dc.index, 1);
          document.querySelector<HTMLInputElement>(`input[type="checkbox"][ipc="${dc.ipc}"]`)!.checked = false;
        }
      }
    }
  }
}
