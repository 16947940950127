import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from './../../environments/environment';
import { EmailsService } from '../services/emails.service';
import { Observable, firstValueFrom } from 'rxjs';
import { Firestore, addDoc, collection, query, where, getDocs, doc, deleteDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  private test: boolean;
  private sr: boolean;

  constructor(
    private http: HttpClient,
    private _firestore : Firestore,
    private _emailsService: EmailsService
  ) {
    this.test = !environment.production;
    this.sr = (sessionStorage.getItem('sr')||'') == '1';
    //this.test = false;
  }

  public async removeStripeCustomerId(uid: string, club_id: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const { stripeRef, reason } = await this.getStripeCustomer(uid, club_id).then(stripeRef => ({ stripeRef, reason: null })).catch((reason: any) => ({ reason, stripeRef: null }));
      if(reason) return reject(reason);

      await deleteDoc(doc(this._firestore, 'users', uid, 'stripe', stripeRef.id));
      return resolve({ document: stripeRef });
    });
  }

  public async getStripeCustomer(uid: string, club_id: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const usersReference = collection(this._firestore, 'users', uid, 'stripe');
      const q = query(usersReference, where('club_id', '==', club_id));
      const snapshot = await getDocs(q);
      if(snapshot.empty){
        this._emailsService.sendEmail('Error getting stripe customer id', `Error for user ${uid}: snapshot empty. Probably the route doesn't exist`);
        return reject({ error: { code: 'not_found', message: 'Stripe customer not found' } });
      }
      const [firstDoc] = snapshot.docs.map(d => ({ ...d.data(), id: d.id }));
      return resolve(firstDoc);
    });
  }

  public async createStripeCustomer(uid: string, showId: string, club_id: string, email: string): Promise<any> {

    return new Promise(async (resolve, reject) => {
      const params = JSON.stringify({ showId, sr: this.sr, test: this.test, email });

      const { customer, reason } = await firstValueFrom(this.http.post(`${environment.api.ed.host}stripe/Customer/createNew.php`, params)).then((r: any) => r).catch((reason: any) => ({ reason }));
      if(reason) return reject(reason);
      console.log('customer', customer);

      const stripeRef = collection(this._firestore, 'users', uid, 'stripe');
      await addDoc(stripeRef, { club_id, customer_id: customer.id, email });

      return resolve({ customer });
    });
  }

  public getTransactionnId(pi: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params = JSON.stringify({ pi, sr: this.sr, test: this.test });
    return this.http.post(`${environment.api.ed.host}stripe/getTransaction/index.php`, params,{ headers: headers });
  }

  public updateStripeCardholderName(paymentMethodId: any, name: any, showId: any): Observable<any> {
    const params = JSON.stringify({ paymentMethodId, name, showId, sr: this.sr, test: this.test });
    return this.http.post(`${environment.api.ed.host}stripe/PaymentMethods/update.php`, params);
  }

  public getStripePaymentMethods(customerId: any, showId: any): Observable<any> {
    const params = JSON.stringify({ customerId, showId, sr: this.sr, test: this.test });
    return this.http.post(`${environment.api.ed.host}stripe/PaymentMethods/list.php`, params);
  }

  public createPaymentIntent(options: CreatePaymentIntentOptions): Observable<any> {
    let params: any = { amount: options.amount, currency: options.currency, idConcurso: options.idConcurso, description: options.description, email: options.email, metadata: options.metadata, customer: options.customer, paymentMethod: options.paymentMethod||'', sr: this.sr, test: this.test };
    if(options.paymentMethodTypes) params.paymentMethodTypes = options.paymentMethodTypes;
    return this.http.post(`${environment.api.ed.host}stripe/PaymentIntent/create.php`, params);
  }

  public searchStripeCustomers(emails: string[], showId: number, test: boolean = false): Promise<any> {
    const params = JSON.stringify({ emails, showId, test });
    const headers = {
      'Content-Type': 'application/json'
    };
    return firstValueFrom(this.http.post(`${environment.api.nest.host}stripe/searchCustomers`, params, { headers }));
  }
}

interface CreatePaymentIntentOptions {
  amount: number;
  currency: string;
  idConcurso: string;
  description: string;
  email: string
  metadata: any;
  customer?: string
  paymentMethod?: string;
  paymentMethodTypes?: string | string[];
}
