<div class="wrapper">
  <div class="navbar-custom">
    <div class="topbar container-fluid">
        <div class="d-flex align-items-center gap-lg-2 gap-1">

            <!-- Topbar Brand Logo -->
            <div class="logo-topbar">
                <!-- Logo light -->
                <a href="index.html" class="logo-light">
                    <span class="logo-lg">
                        <img src="assets/images/logo.png" alt="logo">
                    </span>
                    <span class="logo-sm">
                        <img src="assets/images/logo.png" alt="small logo">
                    </span>
                </a>

                <!-- Logo Dark -->
                <a href="index.html" class="logo-dark">
                    <span class="logo-lg">
                        <img src="assets/images/logo-dark.png" alt="dark logo">
                    </span>
                    <span class="logo-sm">
                        <img src="assets/images/logo-dark.png" alt="small logo">
                    </span>
                </a>
            </div>

        </div>

        <ul class="topbar-menu d-flex align-items-center gap-3">

            <li>
              <div class="version nav-user d-flex" style="padding: 0 10px!important; border: 0 !important; background: unset; text-align: center !important;">
                <span class="my-auto"><small>v{{ version }}</small></span>
              </div>
            </li>

        </ul>
    </div>
  </div>
  <div class="content-page">
    <div class="content">
      <div class="container-fluid">

      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div class="card-body">

                      <!-- Invoice Logo-->
                      <div class="clearfix">
                          <div class="float-start mb-3">
                              <!--img src="assets/images/logo-dark.png" alt="dark logo" height="22"-->
                          </div>
                          <div class="float-end">
                              <h4 class="m-0 d-print-none">Entry Invoice</h4>
                          </div>
                      </div>

                      <!-- Invoice Detail-->
                      <div class="row">

                          <div class="col-sm-6">
                              <div class="float-start mt-3">
                                  <p><b>{{ entry }}</b> - {{ entryInfo?.horse }}</p>
                              </div>
                          </div>

                          <div class="col-sm-4 offset-sm-2">
                              <div class="mt-3 float-sm-end">
                                <div class="d-flex justify-content-between font-13">
                                  <p><strong>Date: </strong></p>
                                  <p>{{ date | date }}</p>
                                </div>
                                <div class="d-flex justify-content-between font-13">
                                  <p><strong>Balance: </strong></p>
                                  <p>{{ entryInfo?.saldo | currency }}</p>
                                </div>
                              </div>
                          </div><!-- end col -->
                      </div>
                      <!-- end row -->

                      <!--div class="row mt-4">
                          <div class="col-sm-4">
                              <h6>Billing Address</h6>
                              <address>
                                  Lynne K. Higby<br>
                                  795 Folsom Ave, Suite 600<br>
                                  San Francisco, CA 94107<br>
                                  <abbr title="Phone">P:</abbr> (123) 456-7890
                              </address>
                          </div>

                          <div class="col-sm-4">
                              <h6>Shipping Address</h6>
                              <address>
                                  Cooper Hobson<br>
                                  795 Folsom Ave, Suite 600<br>
                                  San Francisco, CA 94107<br>
                                  <abbr title="Phone">P:</abbr> (123) 456-7890
                              </address>
                          </div>

                          <div class="col-sm-4">
                              <div class="text-sm-end">
                                  <img src="assets/images/barcode.png" alt="barcode-image" class="img-fluid me-2" />
                              </div>
                          </div>
                      </div-->

                      <!-- Class & Division Fees -->
                      <div class="row" *ngIf="classDivisionFees.length > 0">
                          <div class="col-12">
                              <div class="table-responsive">
                                  <table class="table table-sm">
                                    <thead>
                                      <tr>
                                        <th colspan="3" class="text-center h4">Class & Division Fees</th>
                                      </tr>
                                      <tr >
                                        <th>Class/Division </th>
                                        <th>Quantity</th>
                                        <!--th>Unit Cost</th-->
                                        <th class="text-end">Total</th>
                                      </tr>
                                    </thead>
                                      <tbody>
                                      <tr *ngFor="let fee of classDivisionFees">
                                        <td>{{ fee?.referencia }}</td>
                                        <td>{{ fee?.cantidad }}</td>
                                        <!--td>$1799.00</td-->
                                        <td class="text-end">{{ fee?.costo | currency }}</td>
                                      </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colspan="2" class="text-end"><b>Sub-total</b></td>
                                          <td class="text-end"><b>{{ totalClassDivisionFees | currency }}</b></td>
                                        </tr>
                                      </tfoot>
                                  </table>
                              </div> <!-- end table-responsive-->
                          </div> <!-- end col -->
                      </div>
                      <!-- end row -->

                      <!-- Charges -->
                      <div class="row" *ngIf="charges.length > 0">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-sm">
                                  <thead>
                                    <tr>
                                      <th colspan="3" class="text-center h4">Charges</th>
                                    </tr>
                                    <tr>
                                      <th>Concept</th>
                                      <th>Quantity</th>
                                      <!--th>Unit Cost</th-->
                                      <th class="text-end">Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let charge of charges">
                                      <td>{{ charge?.nombre }}</td>
                                      <td>{{ charge?.cantidad }}</td>
                                      <!--td>$1799.00</td-->
                                      <td class="text-end">{{ charge?.monto | currency }}</td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colspan="2" class="text-end"><b>Sub-total</b></td>
                                      <td class="text-end"><b>{{ totalCharges | currency }}</b></td>
                                    </tr>
                                  </tfoot>
                                </table>
                            </div> <!-- end table-responsive-->
                        </div> <!-- end col -->
                      </div>
                      <!-- end row -->

                      <!-- Feed Orders -->
                      <div class="row" *ngIf="feedOrders.length > 0">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-sm">
                                  <thead>
                                    <tr>
                                      <th colspan="3" class="text-center h4">Feed Orders</th>
                                    </tr>
                                    <tr>
                                      <th>Concept</th>
                                      <th>Quantity</th>
                                      <!--th>Unit Cost</th-->
                                      <th class="text-end">Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let order of feedOrders">
                                      <td>{{ order?.producto }}</td>
                                      <td>{{ order?.cantidad }}</td>
                                      <!--td>$1799.00</td-->
                                      <td class="text-end">{{ order?.total | currency }}</td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colspan="2" class="text-end"><b>Sub-total</b></td>
                                      <td class="text-end"><b>{{ totalFeedOrders | currency }}</b></td>
                                    </tr>
                                  </tfoot>
                                </table>
                            </div> <!-- end table-responsive-->
                        </div> <!-- end col -->
                      </div>
                      <!-- end row -->

                      <!-- Splits -->
                      <div class="row" *ngIf="splits.length > 0">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-sm">
                                  <thead>
                                    <tr>
                                      <th colspan="3" class="text-center h4">Splits</th>
                                    </tr>
                                    <tr>
                                      <th>Trainer</th>
                                      <th>Date</th>
                                      <!--th>Unit Cost</th-->
                                      <th class="text-end">Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let split of splits">
                                      <td>{{ split?.entrenador }}</td>
                                      <td>{{ split?.fecha | date }}</td>
                                      <!--td>$1799.00</td-->
                                      <td class="text-end">{{ split?.monto | currency }}</td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colspan="2" class="text-end"><b>Sub-total</b></td>
                                      <td class="text-end"><b>{{ totalSplits | currency }}</b></td>
                                    </tr>
                                  </tfoot>
                                </table>
                            </div> <!-- end table-responsive-->
                        </div> <!-- end col -->
                      </div>
                      <!-- end row -->

                      <!-- Payments -->
                      <div class="row" *ngIf="payments.length > 0">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-sm">
                                  <thead>
                                    <tr>
                                      <th colspan="3" class="text-center h4">Payments</th>
                                    </tr>
                                    <tr>
                                      <th>Method</th>
                                      <th>Date</th>
                                      <!--th>Unit Cost</th-->
                                      <th class="text-end">Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let payment of payments">
                                      <td>{{ payment?.metodo }}</td>
                                      <td>{{ payment?.fecha_pago | date }}</td>
                                      <!--td>$1799.00</td-->
                                      <td class="text-end">{{ payment?.monto | currency }}</td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td colspan="2" class="text-end"><b>Sub-total</b></td>
                                      <td class="text-end"><b>{{ totalPayments | currency }}</b></td>
                                    </tr>
                                  </tfoot>
                                </table>
                            </div> <!-- end table-responsive-->
                        </div> <!-- end col -->
                      </div>
                      <!-- end row -->

                      <div class="row">
                          <div class="col-sm-6">
                              <div class="clearfix pt-3">
                                  <h6 class="text-muted">Notes:</h6>
                                  <!--small>
                                      All accounts are to be paid within 7 days from receipt of
                                      invoice. To be paid by cheque or credit card or direct payment
                                      online. If account is not paid within 7 days the credits details
                                      supplied as confirmation of work undertaken will be charged the
                                      agreed quoted fee noted above.
                                  </small-->
                              </div>
                          </div>
                          <div class="col-sm-6">
                              <div class="float-end mt-3 mt-sm-0">
                                  <p *ngIf="classDivisionFees.length > 0"><b>Sub-total (Class & Division Fees):</b> <span class="float-end">{{ totalClassDivisionFees | currency }}</span></p>
                                  <p *ngIf="charges.length > 0"><b>Sub-total (Charges):</b> <span class="float-end">{{ totalCharges | currency }}</span></p>
                                  <p *ngIf="feedOrders.length > 0"><b>Sub-total (Feed Orders):</b> <span class="float-end">{{ totalFeedOrders | currency }}</span></p>
                                  <p *ngIf="splits.length > 0"><b>Sub-total (Splits):</b> <span class="float-end">{{ totalSplits | currency }}</span></p>
                                  <p *ngIf="payments.length > 0"><b>Sub-total (Payments):</b> <span class="float-end">{{ totalPayments | currency }}</span></p>
                                  <h3>{{ entryInfo?.saldo | currency }}</h3>
                              </div>
                              <div class="clearfix"></div>
                          </div> <!-- end col -->
                      </div>
                      <!-- end row-->

                      <!--div class="d-print-none mt-4">
                          <div class="text-end">
                              <a href="javascript:window.print()" class="btn btn-primary"><i class="mdi mdi-printer"></i> Print</a>
                              <a href="javascript: void(0);" class="btn btn-info">Submit</a>
                          </div>
                      </div-->
                      <!-- end buttons -->

                  </div> <!-- end card-body-->
              </div> <!-- end card -->
          </div> <!-- end col-->
      </div>
      <!-- end row -->

      </div>
    </div>
  </div>
</div>
