import { Component, OnInit, AfterViewInit } from '@angular/core';
import { EntriesService } from '../services/entries.service';
import { PersonsService } from '../services/persons.service';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit, AfterViewInit {
  public drafts: any[];
  public paying: any[];
  public showName: string;
  public username: string;
  public email: string;
  public showID: string;
  public version: string;
  public toggleMenuListener: any;
  public closeMenuListener: any;
  public reining: boolean;
  public checkoutRoute: string = '/checkout';

  constructor(
    private _entriesService: EntriesService,
    private _personsService: PersonsService,
    private _router: Router
  ) {
    this.drafts = [];
    this.paying = [];
    this.showName = sessionStorage.getItem('showname')||'';
    this.showID = sessionStorage.getItem('showID')||'';
    this.reining = sessionStorage.getItem('reining') === '1';
    this.username = localStorage.getItem('username')||(sessionStorage.getItem('username')||'');
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.version = environment.version;
    if(this.reining) {
      this.checkoutRoute = '/checkout-reining';
    }
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.getDrafts();
    setTimeout(() => {
      const menuToggleBtn = document.querySelector('.button-toggle-menu');
      const menuCloseBtn = document.querySelector('.button-close-fullsidebar');
      this.toggleMenuListener = function () {
        document.documentElement.classList.toggle('sidebar-enable');
      };
      this.closeMenuListener = function () {
        document.documentElement.classList.remove('sidebar-enable');
      };
      menuToggleBtn!.addEventListener('click', this.toggleMenuListener);
      menuCloseBtn!.addEventListener('click', this.closeMenuListener);
    }, 500);
  }

  ngOnDestroy(): void {
    const menuToggleBtn = document.querySelector('.button-toggle-menu');
    const menuCloseBtn = document.querySelector('.button-close-fullsidebar');
    menuToggleBtn!.removeEventListener('click', this.toggleMenuListener);
    menuCloseBtn!.removeEventListener('click', this.closeMenuListener);
    document.documentElement.classList.remove('sidebar-enable');
  }

  public getDrafts() {
    const personEmail: string = this.email;
    const showID: any = sessionStorage.getItem('showID');
    this._personsService.getDocumentByEmail(personEmail).then(response => {
      this._entriesService.getEntries(response.uid, showID, 'draft').then(d => { this.drafts = d; }).catch(error => console.warn(error))
    }).catch(error => console.warn(error))

  }

  public logOut() {
    sessionStorage.clear();
    localStorage.clear();
    this._router.navigate(['/login']);
    return;
  }
}
