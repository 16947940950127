import { Injectable } from '@angular/core';
import { Auth, signInWithEmailAndPassword, user, createUserWithEmailAndPassword, sendPasswordResetEmail } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Firestore, addDoc } from '@angular/fire/firestore';
import { collection, doc, query, where, onSnapshot, setDoc } from "firebase/firestore";
import * as Sentry from "@sentry/browser";
import LogRocket from 'logrocket';

interface User {
  name: string,
  email: string,
  password: string
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  user$: Observable<any>;
  public logRocketSessionURL: string;
  constructor(
    private _auth: Auth,
    private _datePipe: DatePipe,
    private _firestore: Firestore
  ) {
    this.user$ = user(_auth);
  }

  async loginRequest(email: string, password: string): Promise<any> {
    return signInWithEmailAndPassword(this._auth, email, password);
  }

  async logoutRequest() {
    this._auth.signOut()
  }

  async getLoggedUser(): Promise<any> {
    return await this._auth.currentUser;
  }

  async signUpRequest(email: string, password: string, fullname: string): Promise<any> {
    try {
      const response = await createUserWithEmailAndPassword(this._auth, email, password);
      const timestamp = new Date()
      const formattedDate = this._datePipe.transform(timestamp, 'd MMMM yyyy', 'es');
      const formattedTime = this._datePipe.transform(timestamp, 'HH:mm:ss', 'en-US');
      const user = {
        created: `${formattedDate}, ${formattedTime} UTC-6`,
        source: 'ED online entries',
        name: fullname,
        email: email,
        password: password
      }
      const usersReference = collection(this._firestore, 'users');
      return await addDoc(usersReference, user).then(() => { return { error: false, msg: 'The user has been registered' } }
      ).catch(error => error);
    } catch (error) {
      return error
    }
  }

  async resetPassword(email: string): Promise<any> {
    try {
      const response = await sendPasswordResetEmail(this._auth, email);
      return { status: 'success', data: response };
    } catch (error: any) {
      return { error: 'true', message: error.message };
    }
  }

  async createUserDoc(uid: string, email: string) {
    const userDoc = doc(this._firestore, 'users', uid);
    return setDoc(userDoc, { email }, { merge: true }).then(() => ({ uid, email }));
  }

  public async identifyUserLOG(user_document_id: string, email: string, username?: string) {

    // Identify user for error logging systems
    Sentry.setUser({ id: user_document_id || '', username: username || '', email: email || '' });

    // Send logrocket session URL to Sentry
    LogRocket.getSessionURL((url) => {
      this.logRocketSessionURL = url;
      Sentry.setTag('logRocketSession', this.logRocketSessionURL);
    });

    LogRocket.identify(user_document_id || email, {
      email: email,
      name: username || '',
    })
  }
}
