<div class="wrapper">
  <div class="navbar-custom">
    <div class="topbar container-fluid">
        <div class="d-flex align-items-center gap-lg-2 gap-1">

            <!-- Topbar Brand Logo -->
            <div class="logo-topbar">
                <!-- Logo light -->
                <a href="index.html" class="logo-light">
                    <span class="logo-lg">
                        <img src="assets/images/logo.png" alt="logo">
                    </span>
                    <span class="logo-sm">
                        <img src="assets/images/logo.png" alt="small logo">
                    </span>
                </a>

                <!-- Logo Dark -->
                <a href="index.html" class="logo-dark">
                    <span class="logo-lg">
                        <img src="assets/images/logo-dark.png" alt="dark logo">
                    </span>
                    <span class="logo-sm">
                        <img src="assets/images/logo-dark.png" alt="small logo">
                    </span>
                </a>
            </div>

        </div>

        <ul class="topbar-menu d-flex align-items-center gap-3">

            <li>
              <div class="version nav-user d-flex" style="padding: 0 10px!important; border: 0 !important; background: unset; text-align: center !important;">
                <span class="my-auto"><small>v{{ version }}</small></span>
              </div>
            </li>

        </ul>
    </div>
  </div>


  <div class="content-page">
    <div class="container-fluid">
      <div class="row mt-3">



        <div class="accordion accordion-flush" id="accordionFlushExample">

          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button id="accordion-1" class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                {{ HTMLElements.accordion1Text }}
              </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <div class="row">
                  <div>
                    <div class="form-check mb-3 d-flex justify-content-between" *ngFor="let entry of entries; let i = index" (change)="selectEntry(i)" [@itemAnimation]>
                      <span>
                        <input type="radio" id="customRadio{{i}}" name="customRadio" class="form-check-input">
                        <label class="form-check-label" for="customRadio{{i}}"><b>{{ entry.entry }}</b> - {{ entry.horse }} - {{ entry.saldo | currency }}</label>
                      </span>
                      <a href="javascript:;" class="btn btn-light" (click)="openEntryInvoice(entry.entry)"><i class="mdi mdi-eye"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div @inOutAnimation *ngIf="HTMLElements.accordion2Text != ''" class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button id="accordion-2" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                {{ HTMLElements.accordion2Text }}
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"  data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <select class="form-select form-select-sm mb-3" (change)="selectPaymentMethod($event)" id="card-dropdown">
                  <option selected disabled> - </option>
                  <option *ngFor="let card of paymentMethods;let i = index" value="{{ i }}"> {{ card.brand }} ({{ card.last4 }}) </option>
                  <option value="new-card">Add new card</option>
                </select>
                <ng-container *ngIf="HTMLElements.newCardFormVisible; else registeredCardForm">
                  <form id="payment-form" (ngSubmit)="processPayment($event)">
                    <div id="payment-element">
                      <!--Stripe.js injects the Payment Element-->
                    </div>
                    <div class="my-2">
                      <label for="stripeCardHolderName">Cardholder name</label>
                      <input type="text" class="form-control" placeholder="Cardholder Name" id="stripeCardHolderName">
                    </div>
                    <div id="payment-message" class="hidden text-danger"></div>
                    <button class="btn btn-primary" type="submit" id="submit" *ngIf="!stripe?.loading">
                      <span class="spinner-border spinner-border-sm me-1 d-none" role="status" aria-hidden="true" id="spinner"></span>
                      <span id="button-text" >Pay now ({{ agregarComision(this.bill.entry.saldo) | currency }})</span>
                    </button>
                  </form>
                </ng-container>
                <ng-template #registeredCardForm>
                  <form id="payment-form" (ngSubmit)="createPaymentIntent(this.bill.entry,this.bill.paymentMethod.card_id)">
                    <div class="my-2">
                      <label for="stripeCardHolderName">Cardholder name</label>
                      <input type="text" class="form-control" placeholder="Cardholder Name" id="stripeCardHolderName">
                    </div>
                    <div id="payment-message" class="hidden text-danger"></div>
                    <button class="btn btn-primary" type="submit" id="submit" *ngIf="!stripe?.loading">
                      <span class="spinner-border spinner-border-sm me-1 d-none" role="status" aria-hidden="true" id="spinner"></span>
                      <span id="button-text">Pay now ({{ agregarComision(this.bill.entry.saldo) | currency }})</span>
                    </button>
                  </form>
                </ng-template>
                <div *ngIf="stripe?.loading" @inOutAnimation class="loader" style="margin: auto;"></div>
              </div>
            </div>
          </div>

          <div @inOutAnimation *ngIf="HTMLElements.accordion3Text != ''" class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button id="accordion-3" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                  {{ HTMLElements.accordion3Text }}
                </button>
              </h2>
              <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <ng-container *ngIf="HTMLElements.orderSucceded">
                    <div>
                      <img src="../../assets/images/icons/yes.png" alt="Success payment icon" style="width: 5rem;margin: auto;display: block;">
                      <p style="text-align: center;font-size: larger;padding: 1rem;">Payment complete</p>
                      <div class="card" style="background-color: #e9f1ff;text-align: center;color: black;">
                        <h1>{{ agregarComision(this.bill.entry.saldo) | currency }}</h1>
                        <div *ngIf="bill.paymentMethod">
                          <p>Card ending in {{ bill.paymentMethod.last4 }}</p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
