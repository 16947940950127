import { Component } from '@angular/core';
import { successNotification, errorNotification } from '../services/notifications.service';
import { HorsesService } from '../services/horses.service';
import { PersonsService } from '../services/persons.service';

@Component({
  selector: 'app-my-catalog',
  templateUrl: './my-catalog.component.html',
  styleUrls: ['./my-catalog.component.scss']
})
export class MyCatalogComponent {
  public email: string;
  public uid: string;
  horses: Array<any> = [];
  riders: Array<any> = [];
  trainers: Array<any> = [];
  owners: Array<any> = [];


  constructor(private _horsesService: HorsesService, private _personsService: PersonsService) {
    this.email = localStorage.getItem('email') || (sessionStorage.getItem('email') || '');
    this.uid = localStorage.getItem('user_document_id') || '';
  }

  ngOnInit() {
    this._personsService.getDocumentByEmail(this.email).then((doc: any) => {
      this.uid = doc.uid;
      localStorage.setItem('user_document_id', this.uid);
      this.getHorsesSuggestions();
      this.getPeopleSuggestions();
    });
  }

  public async getHorsesSuggestions() {
    try {
      this.horses = await this._horsesService.getHorsesSuggestions(this.uid);
      console.log('Horses: ', this.horses);
    } catch (reason: any) {
      errorNotification(reason.error, `Error horses. ${reason.error}`);
      return;
    }
  }

  public async getPeopleSuggestions() {
    try {
      this.riders = await this._personsService.getPersonsSuggestions(this.uid, 'rider');
      console.log('Riders: ', this.riders);
      this.trainers = await this._personsService.getPersonsSuggestions(this.uid, 'trainer');
      console.log('Trainers: ', this.trainers);
      this.owners = await this._personsService.getPersonsSuggestions(this.uid, 'owner');
      console.log('Owners: ', this.owners);
    } catch (reason: any) {
      errorNotification(reason.error, `Error riders. ${reason.error}`);
      return;
    }
  }

  public deleteHorse(horse: any) {
    console.log('Deleting horse: ', horse);
    this._horsesService.deleteHorseSuggestion(this.email, horse).then(() => {
      successNotification('Horse deleted', 'Horse deleted successfully');
      this.getHorsesSuggestions();
    }).catch((reason: any) => {
      errorNotification(reason.error, `Error deleting horse. ${reason.error}`);
    });
  }

  public deletePerson(person: any, type: string) {
    console.log('Deleting ' + type + ': ', person);
    this._personsService.deletePersonSuggestion(this.email, person, type).then(() => {
      successNotification('Person deleted', 'Person deleted successfully');
      this.getPeopleSuggestions();
    }).catch((reason: any) => {
      errorNotification(reason.error, `Error deleting person. ${reason.error}`);
    });
  }
}
