<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row mt-3">
        <div class="col-md-6 col-12 mt-1">
          <div class="row">
            <div class="lists col-md-6 col-sm-12">
              <h5 class="mt-0 task-header">ENTRIES PENDING TO PAY</h5>
              <div id="task-list-one" class="task-list-items">
                <div class="card" *ngFor="let d of drafts">
                  <div class="card-body p-2">

                    <ul class="list-group">
                      <li class="list-group-item d-flex justify-content-between align-items-center px-2 py-1">
                        <h4 class="m-0">{{ d.horseName }} ({{ d.horseFEI }})</h4>
                        <span class="badge" [ngClass]="{'bg-info': d.discipline == 'jumpers','bg-success': d.discipline == 'hunters','bg-primary': d.discipline == 'FEI_jumpers'}">{{ d.discipline }}</span>
                      </li>
                      <li class="list-group-item px-2 py-1"><i class="mdi mdi-account text-muted me-1"></i> Owner: {{ d.ownername }} {{ d.ownerLastname }}</li>
                      <li class="list-group-item px-2 py-1"><i class="mdi mdi-account-hard-hat text-muted me-1"></i> Trainer: {{ d.trainername }} {{ d.trainerLastname }}</li>
                      <ng-container *ngFor="let rider of d?.riders">
                        <li class="list-group-item px-2 py-1"><i class="mdi mdi-horse-human text-muted me-1"></i> Rider: {{ rider.firstname }} {{ rider.lastname }}</li>
                      </ng-container>
                    </ul>

                    <i *ngIf="actions.moveObject" (click)="moveObject(d,drafts,paying)" class="float-right ri-arrow-right-circle-fill font-25 pointer text-success"></i>
                    <i *ngIf="actions.moveObject" (click)="deleteDraft(d.uid)" class="float-left ri-delete-bin-2-line font-25 pointer text-danger"></i>
                  </div> <!-- end card-body -->
                </div>
              </div> <!-- end company-list-1-->
            </div>

            <div class="lists col-md-6 col-sm-12">
              <h5 class="mt-0 task-header text-uppercase">PAYING NOW</h5>
              <div id="task-list-two" class="task-list-items">
                <div class="card" *ngFor="let d of paying">
                  <div class="card-body p-2">
                    <ul class="list-group">
                      <li class="list-group-item d-flex justify-content-between align-items-center px-2 py-1">
                        <h4 class="m-0">{{ d.horseName }} ({{ d.horseFEI }})</h4>
                        <span class="badge" [ngClass]="{'bg-info': d.discipline == 'jumpers','bg-success': d.discipline == 'hunters','bg-primary': d.discipline == 'FEI_jumpers'}">{{ d.discipline }}</span>
                      </li>
                      <li class="list-group-item px-2 py-1"><i class="mdi mdi-account text-muted me-1"></i> Owner: {{ d.ownername }} {{ d.ownerLastname }}</li>
                      <li class="list-group-item px-2 py-1"><i class="mdi mdi-account-hard-hat text-muted me-1"></i> Trainer: {{ d.trainername }} {{ d.trainerLastname }}</li>
                      <ng-container *ngFor="let rider of d?.riders">
                        <li class="list-group-item px-2 py-1"><i class="mdi mdi-horse-human text-muted me-1"></i> Rider: {{ rider.firstname }} {{ rider.lastname }}</li>
                      </ng-container>
                    </ul>
                    <i *ngIf="actions.moveObject" (click)="moveObject(d,paying,drafts)" class="float-right ri-arrow-left-circle-fill font-25 pointer text-success"></i>
                  </div> <!-- end card-body -->
                </div>
              </div> <!-- end company-list-1-->
              <div *ngIf="actions.canGoToPayment" class="d-grid mt-3">
                <button *ngIf="actions.moveObject" (click)="selectPaymentMethod()" class="btn btn-success btn-sm">Select payment method</button>
                <button *ngIf="!actions.moveObject" (click)="returnToSelectEntries()" class="btn btn-danger btn-sm">Return</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12" *ngIf="actions.StripeFormVisible">
          <app-stripe-payment-form [customers]="[stripeCustomer]" [showId]="showID" [amount]="cartTotals.total" [metadata]="metadata" [description]="description" [defaultCardHolder]="defaultCardHolder" (onError)="handleError($event)" (onConfirmPayment)="handleSubmit($event)"></app-stripe-payment-form>
        </div>
        <div class="col-12">
          <!-- Text Info -->
          <div class="card border-info border mt-3">
            <div class="card-body">
              <!--h5 class="card-title text-info">Special title treatment</h5-->
              <p class="card-text">Click on the right arrow button to select entries you want to pay for now (you can pay for more than one at a time)'.</p>
              <!--a href="javascript: void(0);" class="btn btn-info btn-sm">Button</a-->
            </div> <!-- end card-body-->
          </div> <!-- end card -->
        </div>
      </div>
    </div>
  </div>
</div>
