<div class="leftside-menu menuitem-active">

  <!-- Brand Logo Light -->
  <a href="index.html" class="logo logo-light">
    <span class="logo-lg">
      <img src="assets/images/logo.png" alt="logo">
    </span>
    <span class="logo-sm">
      <img src="assets/images/logo-sm.png" alt="small logo">
    </span>
  </a>

  <!-- Brand Logo Dark -->
  <a href="index.html" class="logo logo-dark">
    <span class="logo-lg">
      <img src="assets/images/logo-dark.png" alt="dark logo">
    </span>
    <span class="logo-sm">
      <img src="assets/images/logo-dark-sm.png" alt="small logo">
    </span>
  </a>

  <!-- Sidebar Hover Menu Toggle Button -->
  <div class="button-sm-hover" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Show Full Sidebar"
    data-bs-original-title="Show Full Sidebar">
    <i class="ri-checkbox-blank-circle-line align-middle"></i>
  </div>

  <!-- Full Sidebar Menu Close Button -->
  <div class="button-close-fullsidebar">
    <i class="ri-close-fill align-middle"></i>
  </div>

  <!-- Sidebar -left -->
  <div class="h-100 show" id="leftside-menu-container" data-simplebar="init">
    <div class="simplebar-wrapper" style="margin: 0px;">
      <div class="simplebar-height-auto-observer-wrapper">
        <div class="simplebar-height-auto-observer"></div>
      </div>
      <div class="simplebar-mask">
        <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
          <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content"
            style="height: 100%; overflow: hidden scroll;">
            <div class="simplebar-content" style="padding: 0px;">
              <!-- Leftbar User -->
              <div class="leftbar-user">
                <a href="pages-profile.html">
                  <img src="assets/images/users/avatar-1.jpg" alt="user-image" height="42"
                    class="rounded-circle shadow-sm">
                  <span class="leftbar-user-name mt-2"></span>
                </a>
              </div>

              <!--- Sidemenu -->
              <ul class="side-nav">
                <li class="side-nav-title">Apps</li>
                <li class="side-nav-item">
                  <a [routerLink]="['/']" routerLinkActive="router-link-active" class="side-nav-link">
                    <i class="mdi mdi-format-list-bulleted"></i>
                    <span> My entries </span>
                  </a>
                </li>
                <li class="side-nav-item">
                  <a [routerLink]="['/catalog']" routerLinkActive="router-link-active" class="side-nav-link">
                    <i class="mdi mdi-account-settings"></i>
                    <span> My Catalog </span>
                  </a>
                </li>
                <li class="side-nav-item">
                  <a [routerLink]="['/add-drops']" routerLinkActive="router-link-active" class="side-nav-link">
                    <i class="mdi mdi-swap-horizontal"></i>
                    <span> Add/Drops </span>
                  </a>
                </li>
                <li class="side-nav-item">
                  <a [routerLink]="[addEntryRoute]" routerLinkActive="router-link-active" class="side-nav-link">
                    <i class="mdi mdi-horse-human"></i>
                    <span> Add entry </span>
                  </a>
                </li>
                <li class="side-nav-item" *ngIf="reining">
                  <a [routerLink]="['/trainer-reservation']" routerLinkActive="router-link-active"
                    class="side-nav-link">
                    <i class="mdi mdi-format-list-bulleted"></i>
                    <span> Trainer Reservation </span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/stalls']" *ngIf="!reining" routerLinkActive="router-link-active" class="side-nav-link">
                    <i class="mdi mdi-table"></i>
                    <span> Stalls </span>
                  </a>
                </li>
                <li class="side-nav-item">
                  <a routerLink="/feed-orders" class="side-nav-link">
                    <i class="mdi mdi-format-list-bulleted"></i>
                    <span> Feed Orders </span>
                  </a>
                </li>
                <li class="side-nav-item" >
                  <a routerLink="/trainer-list" class="side-nav-link">
                    <i class="mdi mdi-calendar"></i>
                    <span> My Schedule </span>
                  </a>
                </li>
                <li class="side-nav-item">
                  <a [routerLink]="[checkoutRoute]" routerLinkActive="router-link-active" class="side-nav-link">
                    <i class="mdi mdi-credit-card"></i>
                    <span> Pay Entries </span>
                  </a>
                </li>
                <li class="side-nav-item">
                  <a [routerLink]="['/history']" routerLinkActive="router-link-active" class="side-nav-link">
                    <i class="mdi mdi-book-open-variant"></i>
                    <span> History </span>
                  </a>
                </li>
              </ul>
              <!--- End Sidemenu -->
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="simplebar-placeholder" style="width: auto; height: 1870px;"></div>
    </div>
    <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
      <div class="simplebar-scrollbar" style="width: 0px; display: none;"></div>
    </div>
    <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
      <div class="simplebar-scrollbar" style="height: 430px; transform: translate3d(0px, 398px, 0px); display: block;">
      </div>
    </div>
  </div>
</div>
