import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
declare let google: any;

@Component({
  selector: 'autocomplete-addresss',
  templateUrl: './autocomplete-addresss.component.html',
  styleUrls: ['./autocomplete-addresss.component.scss']
})
export class AutocompleteAddresssComponent implements OnInit, AfterViewInit {
  @Input() adressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        //componentRestrictions: { country: 'US' },
        types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  getComponent(address_components: any[], type: string, long_name: boolean = true) {
    const component = address_components.find((c: any) => c.types.includes(type));
    if (!component) return '';
    return long_name ? component.long_name : component.short_name;
  }

  invokeEvent(place: any) {
    const street_number = this.getComponent(place.address_components, 'street_number');
    const street = this.getComponent(place.address_components, 'route');
    const address = `${street_number} ${street}`.trim() || this.getComponent(place.address_components, 'premise');
    this.addresstext.nativeElement.value = address;
    this.setAddress.emit({
      street_number,
      street,
      address,
      address2: this.getComponent(place.address_components, 'sublocality') || this.getComponent(place.address_components, 'sublocality_level_1'),
      city: this.getComponent(place.address_components, 'locality'),
      state: this.getComponent(place.address_components, 'administrative_area_level_1', false),
      country: this.getComponent(place.address_components, 'country'),
      zip: this.getComponent(place.address_components, 'postal_code')
    });
  }
}
