import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from 'rxjs';
import { Club } from 'src/app/enums/clubs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private test: boolean;
  private sr: boolean;
  private api: { url: string, key: string };

  constructor(private http: HttpClient,) {
    this.test = !environment.production;
    this.sr = (sessionStorage.getItem('club_id')||'') == Club.splitRock;
    this.api = {
      url: this.test ? 'https://api.equestrian.digital' : 'https://api.equestrian.digital',
      key: '7cc263a1-a490-4337-8302-151490495e56'
    };
  }

  public getStripePaymentMethods(customerId: string, showId: string | number): Promise<any>{
    const params = JSON.stringify({ customerId, showId, sr: this.sr, test: this.test });
    return firstValueFrom(this.http.post(`${this.api.url}/stripe/PaymentMethods/list.php`, params));
  }

  public getEntryInfo(showId: string | number): Promise<any>{
    const headers = { 'x-api-key': this.api.key, 'Content-Type': 'application/json' };
    const params = JSON.stringify({ idConcurso: showId });
    return firstValueFrom(this.http.post(`${this.api.url}/concursos/getEntryInfo/`, params, { headers })).catch(reason => ({ error: true, message: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }

  public createPaymentIntent(opts: CreatePaymentIntentOptions): Promise<any> {
    let params = { ...opts, currency: opts.currency||'usd', customer: opts.customer||'', description: opts.description||'', email: opts.email||'', metadata: {...(opts.metadata||{}), show: opts.idConcurso }, paymentMethod: opts.paymentMethod||'', sr: this.sr, test: this.test };
    if(opts.paymentMethodTypes) params.paymentMethodTypes = opts.paymentMethodTypes;
    return firstValueFrom(this.http.post(`${this.api.url}/stripe/PaymentIntent/create.php`, params));
  }
}
