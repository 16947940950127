<div class="wrapper">
    <app-top-menu></app-top-menu>
    <app-left-menu></app-left-menu>
    <div class="content-page">
        <div class="container-fluid">
            <div class="row mt-3">
                <h2>Catalog</h2>
                <div class="row">
                    <div class="col-6">
                        <div class="card">
                            <div class="card-header d-flex justify-content-between align-items-center">
                                <h4 class="header-title">Horses</h4>
                            </div>
                            <div class="card-body pt-0">
                                <div class="table-responsive mt-n2">
                                    <table class="table table-centered table-hover table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">USEF</th>
                                                <th scope="col">FEI</th>
                                                <th scope="col">NRHA</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let horse of horses">
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <!-- <div class="flex-shrink-0">
                                                            <img class="rounded-circle"
                                                                src="assets/images/users/avatar-1.jpg" alt="Avtar image"
                                                                width="31">
                                                        </div> -->
                                                        <div class="flex-grow-1 ms-2">
                                                            {{horse.name}}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{horse.USEF}}</td>
                                                <td>{{horse.FEI}}</td>
                                                <td>
                                                    {{horse.nrha}}
                                                </td>
                                                <td>
                                                    <!-- <a href="javascript:void(0);" class="font-18 text-info me-2"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        aria-label="Edit" data-bs-original-title="Edit"><i
                                                            class="uil uil-pen"></i></a> -->
                                                    <a href="javascript:void(0);" (click)="deleteHorse(horse.uid)"
                                                        class="font-18 text-danger" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" aria-label="Delete"
                                                        data-bs-original-title="Delete"><i
                                                            class="uil uil-trash"></i></a>
                                                </td>
                                            </tr> <!-- end tr -->


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <ng-container
                            *ngTemplateOutlet="personTemplate; context: {type: 'rider', title: 'Riders', collection: riders}"></ng-container>
                    </div>

                    <div class="col-6">
                        <ng-container
                            *ngTemplateOutlet="personTemplate; context: {type: 'trainer', title: 'Trainers', collection: trainers}"></ng-container>
                    </div>
                    <div class="col-6">
                        <ng-container
                            *ngTemplateOutlet="personTemplate; context: {type: 'owner', title: 'Owners', collection: owners}"></ng-container>
                    </div>


                    <ng-template #personTemplate let-type="type" let-title="title" let-collection="collection">
                        <div class="card">
                            <div class="card-header d-flex justify-content-between align-items-center">
                                <h4 class="header-title">{{title}}</h4>
                            </div>
                            <div class="card-body pt-0">
                                <div class="table-responsive mt-n2">
                                    <table class="table table-centered table-hover table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">USEF</th>
                                                <th scope="col">FEI</th>
                                                <th scope="col">NRHA</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let person of collection">
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="flex-grow-1 ms-2">
                                                            {{person.firstname}} {{person.lastname}}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{person.USEF}}</td>
                                                <td>{{person.FEI}}</td>
                                                <td>
                                                    {{person.NRHA}}
                                                </td>
                                                <td>
                                                    <!-- <a href="javascript:void(0);" class="font-18 text-info me-2"
                                                        data-bs-toggle="tooltip" data-bs-placement="top"
                                                        aria-label="Edit" data-bs-original-title="Edit"><i
                                                            class="uil uil-pen"></i></a> -->
                                                    <a href="javascript:void(0);"
                                                        (click)="deletePerson(person.uid, type)"
                                                        class="font-18 text-danger" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" aria-label="Delete"
                                                        data-bs-original-title="Delete"><i
                                                            class="uil uil-trash"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                </div>

                <div class="row">
                    <div class="card">
                        <div class="card-body">
                            <i class="uil uil-comment-alt-lines font-18" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Chat" data-bs-original-title="Chat"></i>
                            This section is under active development, expect to see features like adding and editing horses and people soon.
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>